import { useRef, useState } from "react";
import { Box, ButtonBase, Drawer, Toolbar, Tooltip } from "@material-ui/core";
import {
  Layers as LayersIcon,
  Settings as SettingsIcon,
  Assignment as AssignmentIcon,
  Link as LinkIcon,
  Help as HelpIcon,
} from "@material-ui/icons";
import styled from "styled-components";
import clsx from "clsx";

import { ActionBarActiveContent } from "./ActionBarActiveContent";
import { useTranslation } from "react-i18next";

export enum ACTION_BAR_ITEMS {
  overlayers,
  substanceConfig,
  reports,
  links,
  information,
}

export function ActionBar(props: {}) {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState<ACTION_BAR_ITEMS | null>(null);

  const overlayersNavRef = useRef(null);

  function toggleActive(item: ACTION_BAR_ITEMS) {
    if (activeItem === item) setActiveItem(null);
    else setActiveItem(item);
  }

  return render();

  function render() {
    return (
      <Styles>
        <Drawer variant="permanent" anchor="right">
          <Toolbar />

          <Tooltip title={t<string>("label.layerSelection")} placement="left">
            <ActionBarNavigationItem
              ref={overlayersNavRef}
              onClick={() => toggleActive(ACTION_BAR_ITEMS.overlayers)}
              className={clsx({
                active: activeItem === ACTION_BAR_ITEMS.overlayers,
              })}
            >
              <LayersIcon />
            </ActionBarNavigationItem>
          </Tooltip>

          <Tooltip title={t<string>("label.configuration")} placement="left">
            <ActionBarNavigationItem
              onClick={() => toggleActive(ACTION_BAR_ITEMS.substanceConfig)}
              className={clsx({
                active: activeItem === ACTION_BAR_ITEMS.substanceConfig,
              })}
            >
              <SettingsIcon />
            </ActionBarNavigationItem>
          </Tooltip>

          <Tooltip title={t<string>("label.reports")} placement="left">
            <ActionBarNavigationItem
              onClick={() => toggleActive(ACTION_BAR_ITEMS.reports)}
              className={clsx({
                active: activeItem === ACTION_BAR_ITEMS.reports,
              })}
            >
              <AssignmentIcon />
            </ActionBarNavigationItem>
          </Tooltip>

          <Box flexGrow={1} />

          <Tooltip title={t<string>("label.links")} placement="left">
            <ActionBarNavigationItem
              onClick={() => toggleActive(ACTION_BAR_ITEMS.links)}
              className={clsx({
                active: activeItem === ACTION_BAR_ITEMS.links,
              })}
            >
              <LinkIcon />
            </ActionBarNavigationItem>
          </Tooltip>

          <Tooltip title={t<string>("label.information")} placement="left">
            <ActionBarNavigationItem
              onClick={() => toggleActive(ACTION_BAR_ITEMS.information)}
              className={clsx({
                active: activeItem === ACTION_BAR_ITEMS.information,
              })}
            >
              <HelpIcon />
            </ActionBarNavigationItem>
          </Tooltip>
        </Drawer>

        <ActionBarActiveContent
          active={activeItem}
          refs={{
            overlayers: overlayersNavRef,
          }}
          onClose={() => {
            setActiveItem(null);
          }}
        />
      </Styles>
    );
  }
}

const Styles = styled.div`
  .full-height-content {
    position: absolute;
    right: ${({ theme }) => theme.actionBar.width}px;
    top: 0;
    width: auto;
    height: 100%;
    background: blue;
    z-index: 1000;
  }

  .MuiDrawer-root {
    width: ${({ theme }) => theme.actionBar.width}px;
  }
`;

const ActionBarNavigationItem = styled(ButtonBase)`
  width: ${({ theme }) => theme.actionBar.width}px;
  height: ${({ theme }) => theme.actionBar.width}px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background-color: #f2f2f2;
  }

  .MuiSvgIcon-root {
    fill: #3e4960;
  }
`;
