import { TableRow, TableCell } from "@material-ui/core";
import { LegendStandardType, Standard, Station, Statistic } from "../../types";
import { formatPct, pct } from "../../utils/format.utils";
import { getValue } from "../../utils/station.utils";

interface Props {
  stations: Station[];
  standard: Standard;
  statistic: Statistic;
}

export function LegendItemsSummaryRows(props: Props) {
  const { stations, standard, statistic } = props;

  const legendItems = standard.legend.filter(
    (item) => item.type === LegendStandardType.LEGEND_ITEM
  );

  function getLowerBoundLegendItem(
    legend: Standard["legend"],
    legendItem: Standard["legend"][0]
  ): Standard["legend"][0] | undefined {
    const sortedLegend = legend.sort((a, b) => a.upperBound - b.upperBound);
    const currentIndex = sortedLegend.findIndex(
      (item) => item.id === legendItem.id
    );
    return legend[currentIndex - 1];
  }

  function countForLegendItem(
    stations: Station[],
    standard: Standard,
    statistic: Statistic,
    legendItem: Standard["legend"][0]
  ) {
    return stations.reduce((count: number, station) => {
      if (station.measurementCount === station.loqMeasurementCount)
        return count;

      const value = getValue(station, statistic);
      const previousLegendItem = getLowerBoundLegendItem(
        standard.legend,
        legendItem
      );

      if (
        value !== null &&
        value > previousLegendItem?.upperBound! &&
        value <= legendItem.upperBound
      )
        return (count += 1);
      else return count;
    }, 0);
  }

  return render();

  function render() {
    return (
      <>
        {legendItems.map((item) => {
          const count = countForLegendItem(stations, standard, statistic, item);
          return (
            <TableRow key={item.id}>
              <TableCell>{item.label}</TableCell>
              <TableCell align="right">{count}</TableCell>
              <TableCell align="right">
                {formatPct(pct(count, stations.length), 0)}
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  }
}
