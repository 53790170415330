import { Link, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ReportsFormData } from "../forms/ReportsForm/ReportsForm";
import { useSubstanceSourceCategories } from "../hooks";
import { getLocationReports, getRegionReports } from "../config/reports";
import { HistoryState, Station } from "../types";
import { useParameterSelection } from "../hooks/useParamaterSelection";

interface Props {
  values: ReportsFormData;
  stationCode?: Station["code"];
}

export function ReportsResults(props: Props) {
  const { values } = props;
  const history = useHistory<HistoryState>();
  const location = useLocation();
  const { selection: parameterSelection } = useParameterSelection();

  const { data: sourceCategories } = useSubstanceSourceCategories(
    values.substanceSymbol
  );

  function handleClick(download: HistoryState["download"]) {
    const state = { download };
    history.push({
      ...location,
      state,
    });
  }

  // render the component
  return render();

  function render() {
    if (values.substanceSymbol === "") return null;
    if (values.from === "") return null;
    if (values.to === "") return null;

    return (
      <Styles>
        {renderRegionReports()}
        {props.stationCode && renderStationReports(props.stationCode)}
      </Styles>
    );
  }

  function renderRegionReports() {
    if (!sourceCategories) return null;

    return (
      <>
        <Typography variant="h2" gutterBottom>
          Reports for a region
        </Typography>

        <ul className="report-list">
          {getRegionReports(values, sourceCategories).map((report) => (
            <div key={report.text} className="report-list-item">
              <Link
                href="#"
                onClick={() => {
                  handleClick({
                    url: report.href.pdf,
                    visualName: `${report.text}.pdf`,
                  });
                }}
                className="pdf"
              >
                {report.text}
              </Link>
              <Link
                href="#"
                onClick={() => {
                  handleClick({
                    url: report.href.xlsx,
                    visualName: `${report.text}.xlsx`,
                  });
                }}
                className="xlsx"
              >
                xlsx
              </Link>
            </div>
          ))}
        </ul>
      </>
    );
  }

  function renderStationReports(stationCode: Station["code"]) {
    if (!parameterSelection.sourceCategoryId) {
      console.warn("Can not render station reports without sourceCategoryId");
      return null;
    }

    return (
      <>
        <Typography variant="h2" gutterBottom>
          Reports for selected station {decodeURIComponent(stationCode)}
        </Typography>

        <ul className="report-list">
          {getLocationReports(
            values,
            stationCode,
            parameterSelection.sourceCategoryId
          ).map((report) => (
            <div key={report.text} className="report-list-item">
              <Link
                href="#"
                onClick={() => {
                  handleClick({
                    url: report.href.pdf,
                    visualName: `${report.text}.pdf`,
                  });
                }}
                className="pdf"
              >
                {report.text}
              </Link>
              <Link
                href="#"
                onClick={() => {
                  handleClick({
                    url: report.href.xlsx,
                    visualName: `${report.text}.xlsx`,
                  });
                }}
                className="xlsx"
              >
                xlsx
              </Link>
            </div>
          ))}
        </ul>
      </>
    );
  }
}

const Styles = styled.div`
  .report-list {
    padding: 0;
  }
  .report-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing(1)}px;

    .pdf {
      // ensure some spacing on the side
      margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
  }
`;
