import { isNil, maxBy } from "lodash-es";
import {
  Overlayer,
  OverlayerWithYearVariants,
  OverlayerWmsLayer,
} from "../types";

export function isOverlayerWithYearVariants(
  overlayer: any
): overlayer is OverlayerWithYearVariants {
  return !isNil(overlayer.variants);
}

export function getBestYearVariant(
  overlayer: OverlayerWithYearVariants,
  year = 9999
): OverlayerWithYearVariants["variants"][0] {
  const match = overlayer.variants
    .filter((overlayer) => overlayer.year >= (year || -1))
    .sort((a, b) => (a.year || 0) - (b.year || 1))[0];
  const mostRecent = maxBy(overlayer.variants, "year");

  if (match) {
    return match;
  } else if (mostRecent) {
    return mostRecent;
  } else {
    console.warn("No best year variant found, use first element");
    return overlayer.variants[0];
  }
}

export function getLayersBySelection(
  overlayers: Overlayer[],
  activeLayers: Overlayer["key"][],
  year: number | undefined
): Array<OverlayerWmsLayer> {
  return overlayers
    .filter((overlayer) => activeLayers.includes(overlayer.key))
    .map((overlayer) => {
      if (isOverlayerWithYearVariants(overlayer)) {
        const bestYearVariant = getBestYearVariant(overlayer, year);
        return {
          ...overlayer,
          wmsLayer: { ...overlayer.wmsLayer, layers: bestYearVariant.layers },
        };
      }
      // else
      return {
        ...overlayer,
        wmsLayer: { ...overlayer.wmsLayer, layers: overlayer.layers },
      };
    });
}
