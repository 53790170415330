import {
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Fab,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Info as InfoIcon,
  Toc as TocIcon,
} from "@material-ui/icons";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { Station } from "../../types";
import { useStandards, useStations, useStatistics } from "../../hooks";
import { formatPct } from "../../utils/format.utils";
import { LegendItemsSummaryRows } from "./LegendItemsSummaryRows";
import { LowerThenLOQSummaryRows } from "./LowerThenLOQSummaryRows";
import { NoAssesmentPossbileSummaryRows } from "./NoAssesmentPossbileSummaryRows";
import { useTranslation } from "react-i18next";

interface Props {
  substanceSymbol: string;
  sourceId: number;
  year: number;
  standardId: number;
  statisticId: number;
}

export function StationsSummary(props: Props) {
  const { substanceSymbol, sourceId, year, standardId, statisticId } = props;

  const { t } = useTranslation();

  const { data: stations } = useStations(substanceSymbol, sourceId, year);
  const { data: standards } = useStandards(substanceSymbol, sourceId);
  const { data: statistics } = useStatistics(standardId);
  const [isOpen, setIsOpen] = useState(true);

  /**
   * Memo
   */
  const standard = useMemo(
    () => standards?.find((standard) => standard.id === standardId),
    [standards, standardId]
  );
  const statistic = useMemo(
    () => statistics?.find((statistic) => statistic.id === statisticId),
    [statistics, statisticId]
  );

  const totalAtLeastOneMeasurement = useMemo(
    () => getTotalAtLeastOneMeasurement(stations || []),
    [stations]
  );

  /**
   * Functions
   */
  function getTotalAtLeastOneMeasurement(stations: Station[]) {
    const result = stations.reduce((total: number, station) => {
      if (
        station.measurementCount > 0 &&
        station.loqMeasurementCount &&
        station.measurementCount !== station.loqMeasurementCount
      )
        return total + 1;
      else return total;
    }, 0);

    return { total: result, percentage: (result / stations.length) * 100 };
  }

  // render the component
  return render();

  function render() {
    if (isOpen) return renderOpen();
    else return renderClosed();
  }

  function renderOpen() {
    if (!stations) return null;
    if (!standard) return null;
    if (!statistic) return null;

    return (
      <StyledCard>
        <CardHeader
          action={
            <>
              <IconButton onClick={() => setIsOpen(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
          title={t("label.stationSummary")}
        />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="total-label">
                    Total&nbsp;<small>( {stations?.length || 0} )</small>
                    <Tooltip
                      title="at least one measurement > LOQ"
                      placement="right"
                    >
                      <InfoIcon fontSize="small" color="action" />
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell align="right">
                  {totalAtLeastOneMeasurement.total}
                </TableCell>
                <TableCell align="right">
                  {formatPct(totalAtLeastOneMeasurement.percentage, 0)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <NoAssesmentPossbileSummaryRows
                stations={stations}
                standard={standard}
                statistic={statistic}
              />
              <LegendItemsSummaryRows
                stations={stations}
                standard={standard}
                statistic={statistic}
              />
              <LowerThenLOQSummaryRows
                stations={stations}
                standard={standard}
                statistic={statistic}
              />
            </TableBody>
          </Table>
        </CardContent>
      </StyledCard>
    );
  }

  function renderClosed() {
    return (
      <Fab size="medium" onClick={() => setIsOpen(!isOpen)}>
        <TocIcon fontSize="small" />
      </Fab>
    );
  }
}

const StyledCard = styled(Card)`
  .total-label {
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
      margin-left: ${({ theme }) => theme.spacing(1)}px;
    }
  }

  .MuiCardHeader-root {
    padding-bottom: 0;
  }

  .MuiTableRow-root:last-child {
    .MuiTableCell-body {
      border: none;
    }
  }
  .MuiTableCell-root {
    padding: ${({ theme }) => theme.spacing(0.5, 1)};
  }
`;
