import {
  Toolbar,
  Typography,
  List,
  ListSubheader,
  ListItemText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ActionBarDrawer } from "../ ActionBar/ActionBarDrawer";
import { ActionBarDrawerHeader } from "../ ActionBar/ActionBarDrawerHeader";
import { linksConfiguration } from "../../config/links";
import { ListItemLink } from "../ListItemLink";

interface Props {
  onClose: () => void;
}

export function Links(props: Props) {
  const { t } = useTranslation();

  // render the component
  return render();

  function render() {
    return (
      <StyledActionBarDrawer width={300} onClose={props.onClose}>
        <Toolbar />

        <ActionBarDrawerHeader
          title={t("label.links")}
          onClose={props.onClose}
        />

        <div>
          {linksConfiguration(t).map((linksSection, i) => (
            <List
              key={i}
              subheader={
                <ListSubheader disableSticky>
                  {linksSection.title}
                </ListSubheader>
              }
            >
              {linksSection.links.map((item, j) => (
                <ListItemLink key={[item.url, j].join()} href={item.url}>
                  <ListItemText primary={item.text} />
                </ListItemLink>
              ))}
            </List>
          ))}
        </div>
      </StyledActionBarDrawer>
    );
  }
}

const StyledActionBarDrawer = styled(ActionBarDrawer)`
  .MuiPaper-root {
    padding: ${({ theme }) => theme.spacing(3, 0)};
  }

  .MuiList-root {
    width: 100%;

    &:not(:first-child) {
      margin-top: ${({ theme }) => theme.spacing(2)}px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
    }
  }

  .MuiListItemText-root {
    overflow-wrap: anywhere;
  }
`;
