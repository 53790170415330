import { useEffect } from "react";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash-es";

import { DEFAULT } from "../../types";
import { usePrevious } from "../../hooks/usePrevious";
import { ReportsFormData } from "./ReportsForm";

export function ReportsWaterfall() {
  const { values, setValues } = useFormikContext<ReportsFormData>();
  const previousValues = usePrevious<typeof values>(values);

  useEffect(() => {
    // Check for substance change
    if (
      !isEmpty(previousValues?.substanceSymbol) &&
      previousValues?.substanceSymbol !== values.substanceSymbol
    ) {
      setValues({
        ...values,
        from: "",
        to: "",
        groundWaterStandard: DEFAULT,
        surfaceWaterStandard: DEFAULT,
      });
    }

    // check for from & to change
    if (
      !isEmpty(previousValues?.from) &&
      !isEmpty(
        (previousValues?.to && previousValues?.from !== values.from) ||
          previousValues?.to !== values.to
      )
    ) {
      setValues({
        ...values,
        groundWaterStandard: DEFAULT,
        surfaceWaterStandard: DEFAULT,
      });
    }
  }, [previousValues, previousValues?.to, setValues, values]);
  return null;
}
