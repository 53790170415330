import { Toolbar, Typography } from "@material-ui/core";
import styled from "styled-components";
import {
  SubstanceConfigMutationData,
  useSubstance,
  useSubstanceConfigUpdateMutation,
} from "../../hooks";
import { Substance } from "../../types";
import { SubstanceConfigForm } from "../../forms/SubstanceConfigForm";
import { useTranslation } from "react-i18next";
import { ActionBarDrawer } from "../ ActionBar/ActionBarDrawer";
import { ActionBarDrawerHeader } from "../ ActionBar/ActionBarDrawerHeader";
import { ActionBarDrawerContent } from "../ ActionBar/ActionBarDrawerContent";

interface Props {
  substanceSymbol: Substance["symbol"];
  onClose: () => void;
}

export function SubstanceConfig(props: Props) {
  const { t } = useTranslation();
  const { data: substance } = useSubstance(props.substanceSymbol);
  const updateMutation = useSubstanceConfigUpdateMutation(
    props.substanceSymbol
  );

  /**
   * Functions
   */
  function handleSubmit(values: SubstanceConfigMutationData) {
    updateMutation.mutateAsync(values);
  }

  function handleReset() {
    if (!substance) return;
    updateMutation.mutateAsync({
      caaEqs: substance.limits.aaEqs,
      cmacEqs: substance.limits.macEqs,
      crac: substance.limits.rac,
      cgwLimit: substance.limits.gwLimit,
    });
  }

  // render the component
  return render();

  function render() {
    if (!substance) return null;

    return (
      <StyledActionBarDrawer width={310} onClose={props.onClose}>
        <Toolbar />

        <ActionBarDrawerHeader
          title={t("label.configuration")}
          onClose={props.onClose}
        />
        <ActionBarDrawerContent>
          <Typography variant="h4" gutterBottom className="substance">
            {t("label.forSubstance", { substance: substance.name })}
          </Typography>

          <SubstanceConfigForm
            data={substance.customLimits}
            substance={substance}
            isLoading={updateMutation.isLoading}
            errors={updateMutation.error?.response?.data}
            onSubmit={handleSubmit}
            onReset={handleReset}
          />
        </ActionBarDrawerContent>
      </StyledActionBarDrawer>
    );
  }
}

const StyledActionBarDrawer = styled(ActionBarDrawer)`
  .substance {
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;
