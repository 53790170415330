import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";

import { APIError, Substance } from "../types";
import { ButtonGrid } from "./form-components/ButtonGrid";
import { ButtonLoading } from "../components/ButtonLoading";
import { ServerErrors } from "./form-components/ServerErrors";
import { useSubstanceConfigSchema } from "../schemas/substance-config.schema";
import { SubstanceConfigMutationData } from "../hooks";
import { adornments } from "../config/adornments";
import styled from "styled-components";

export type SubstanceConfigFormData = SubstanceConfigMutationData;

interface Props {
  data: SubstanceConfigFormData;
  substance: Substance;
  onReset: () => void;
  onSubmit: (values: SubstanceConfigFormData) => void;
  isLoading: boolean;
  errors?: APIError[] | null | undefined;
  formRef?: React.MutableRefObject<FormikProps<SubstanceConfigFormData> | null>;
}

export function SubstanceConfigForm(props: Props) {
  const { t } = useTranslation();
  const validationSchema = useSubstanceConfigSchema();

  const initialValues: SubstanceConfigFormData = {
    caaEqs: props.data.caaEqs,
    cmacEqs: props.data.cmacEqs,
    crac: props.data.crac,
    cgwLimit: props.data.cgwLimit,
  };

  return render();

  function render() {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: SubstanceConfigFormData) => {
          return props.onSubmit(values);
        }}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <StyledForm>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2">
                  Custom standards for surface water
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="caaEqs"
                  label={t("label.caaEqs")}
                  type="number"
                  variant="outlined"
                  fullWidth
                  helperText={t("label.defaultValue", {
                    value: props.substance.limits.aaEqs,
                  })}
                  InputProps={adornments(t).microgramPerLiter}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="cmacEqs"
                  label={t("label.cmacEqs")}
                  type="number"
                  variant="outlined"
                  fullWidth
                  helperText={t("label.defaultValue", {
                    value: props.substance.limits.macEqs,
                  })}
                  InputProps={adornments(t).microgramPerLiter}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="crac"
                  label={t("label.crac")}
                  type="number"
                  variant="outlined"
                  fullWidth
                  helperText={t("label.defaultValue", {
                    value: props.substance.limits.rac,
                  })}
                  InputProps={adornments(t).microgramPerLiter}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2">
                  Custom standards for groundwater
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="cgwLimit"
                  label={t("label.cgwLimit")}
                  type="number"
                  variant="outlined"
                  fullWidth
                  helperText={t("label.defaultValue", {
                    value: props.substance.limits.gwLimit,
                  })}
                  InputProps={adornments(t).microgramPerLiter}
                />
              </Grid>

              {props.errors && (
                <Grid item xs={12}>
                  <ServerErrors data={props.errors} />
                </Grid>
              )}

              <ButtonGrid container item xs={12} justify="flex-end">
                <Button variant="text" onClick={props.onReset}>
                  {t("label.reset")}
                </Button>
                <ButtonLoading
                  isLoading={isSubmitting || props.isLoading}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {t("label.save")}
                </ButtonLoading>
              </ButtonGrid>
            </Grid>
          </StyledForm>
        )}
      </Formik>
    );
  }
}

const StyledForm = styled(Form)`
  .MuiGrid-container:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing(3)}px;
  }
`;
