import { Standard, LegendStandardType } from "../types";

export function getLegendItem(value: number, legend: Standard["legend"]) {
  return legend.find((legendItem) => value <= legendItem.upperBound);
}

export function getLegendType(
  legend: Standard["legend"],
  type: LegendStandardType
) {
  return legend.find((legendItem) => legendItem.type === type);
}
