import { atom } from "jotai";
import { ReportsFormData } from "../forms/ReportsForm/ReportsForm";
import { DEFAULT } from "../types";

export const reportsAtom = atom<ReportsFormData>({
  substanceSymbol: "",
  from: "",
  to: "",
  surfaceWaterStandard: DEFAULT,
  groundWaterStandard: DEFAULT,
});
