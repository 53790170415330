import { Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";

export const PaperLoader = (props: { width: number }) => {
  return (
    <StyledPaper width={props.width}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </StyledPaper>
  );
};
PaperLoader.defaultProps = {
  width: 300,
};

const StyledPaper = styled(Paper)<{ width?: number }>`
  width: ${({ width }) => width}px;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(3)}px;
`;
