import { useMemo, Suspense, useState } from "react";
import {
  Fab,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from "@material-ui/core";
import { Close as CloseIcon, Palette as PaletteIcon } from "@material-ui/icons";
import styled from "styled-components";

import { useParameterSelection } from "../../hooks/useParamaterSelection";
import { getLayersBySelection } from "../../utils/overlayer.utils";
import { overlayers } from "../../config/overlayers";
import { useMap } from "../../context/map.context";
import { LegendOverlayer } from "./LegendOverlayer";
import { LegendStandard } from "./LegendStandard";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";

interface Props {}

export function Legend(props: Props) {
  const mapContext = useMap();
  const { t } = useTranslation();
  const { selection } = useParameterSelection();

  // local state
  const [isOpen, setIsOpen] = useState(true);

  /**
   * Memo
   */
  const activeOverlayers = useMemo(
    () =>
      getLayersBySelection(
        overlayers,
        mapContext.state.activeOverlayers,
        selection.year || undefined
      ),
    [mapContext.state.activeOverlayers, selection.year]
  );

  // render the component
  return render();

  function render() {
    if (
      (!selection.substanceSymbol ||
        !selection.sourceId ||
        !selection.standardId) &&
      isEmpty(activeOverlayers)
    )
      return null;

    if (isOpen) return renderOpen();
    else return renderClosed();
  }

  function renderOpen() {
    return (
      <StyledLegendContainerCard>
        <CardHeader
          title={t("label.legend")}
          action={
            <>
              <IconButton onClick={() => setIsOpen(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
        <CardContent>
          {selection.substanceSymbol &&
            selection.sourceId &&
            selection.standardId && (
              <Card elevation={0}>
                <CardHeader title={t("label.stationData")} />
                <CardContent>
                  <Suspense fallback={null}>
                    <LegendStandard
                      substanceSymbol={selection.substanceSymbol}
                      sourceId={selection.sourceId}
                      standardId={selection.standardId}
                    />
                  </Suspense>
                </CardContent>
              </Card>
            )}

          {activeOverlayers.map((overlayer) => (
            <LegendOverlayer key={overlayer.key} overlayer={overlayer} />
          ))}
        </CardContent>
      </StyledLegendContainerCard>
    );
  }

  function renderClosed() {
    return (
      <Fab size="medium" onClick={() => setIsOpen(!isOpen)}>
        <PaletteIcon fontSize="small" />
      </Fab>
    );
  }
}

const StyledLegendContainerCard = styled(Card)`
  > .MuiCardHeader-root {
    padding-bottom: ${({ theme }) => theme.spacing(0)}px;
  }
  .MuiCard-root > .MuiCardHeader-root {
    padding: 0;
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: row;

    .MuiCard-root {
      &:not(:last-child) {
        margin-right: ${({ theme }) => theme.spacing(1)}px;
      }

      .MuiCardHeader-title {
        font-weight: 100;
      }
      > .MuiCardContent-root {
        padding: 0;
        display: flex;
        flex-direction: row;
      }
    }
  }
`;
