import { useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { auth } from "marvin-auth-kit";

import { APIError } from "../types";
import { ButtonLoading } from "../components/ButtonLoading";
import { AppLayout } from "../layouts/AppLayout";
import { ServerErrors } from "../forms/form-components/ServerErrors";
import { ButtonGrid } from "../forms/form-components/ButtonGrid";
import { useLoginSchema } from "../schemas/login.schema";
import { isEmpty } from "lodash-es";

export function LoginPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const validationSchema = useLoginSchema();

  const [serverErrors, setServerErrors] = useState<APIError[]>([]);

  const initialValues = { email: "", password: "" };

  return render();

  function render() {
    return (
      <AppLayout>
        <Styles>
          <Box pb={3}>
            <Typography variant="h1">
              {t("label.loginToAccessWaterprotect")}
            </Typography>
          </Box>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              setServerErrors([]);

              const result = await auth.login(values.email, values.password);

              actions.setSubmitting(false);

              if (result.isOk()) {
                return history.push("/stations");
              } else if (result.isErr()) {
                setServerErrors(result.error);
              } else {
                // fallback error
                // enqueueSnackbar(t("label.couldNotSignIn"), {
                //   variant: "error",
                //   preventDuplicate: true,
                // });
              }
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label={t("label.email")}
                      name="email"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label={t("label.password")}
                      type="password"
                      name="password"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  {!isEmpty(serverErrors) && (
                    <Grid item xs={12}>
                      <ServerErrors data={serverErrors} />
                    </Grid>
                  )}

                  <ButtonGrid container item xs={12} justify="center">
                    <Button
                      variant="text"
                      disabled={isSubmitting}
                      component={Link}
                      to="/"
                    >
                      {t("label.cancel")}
                    </Button>
                    <ButtonLoading type="submit" isLoading={isSubmitting}>
                      {t("label.login")}
                    </ButtonLoading>
                  </ButtonGrid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Styles>
      </AppLayout>
    );
  }
}

const Styles = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 340px;
  max-width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(1)}px;

  box-sizing: border-box;
`;
