import { Toolbar, Typography } from "@material-ui/core";
import { FormikProps } from "formik";
import { useAtom } from "jotai";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ActionBarDrawer } from "../ ActionBar/ActionBarDrawer";
import { ActionBarDrawerContent } from "../ ActionBar/ActionBarDrawerContent";
import { ActionBarDrawerHeader } from "../ ActionBar/ActionBarDrawerHeader";
import { reportsAtom } from "../../atoms";
import { ReportsForm } from "../../forms/ReportsForm/ReportsForm";

interface Props {
  onClose: () => void;
}

export function Reports(props: Props) {
  const { t } = useTranslation();
  const params = useParams<{ stationCode?: string }>();

  const [values, setValues] = useAtom(reportsAtom);

  // render the component
  return render();

  function render() {
    return (
      <StyledActionBarDrawer width={450} onClose={props.onClose}>
        <Toolbar />

        <ActionBarDrawerHeader
          title={t("label.reports")}
          onClose={props.onClose}
        />

        <ActionBarDrawerContent>
          <Typography variant="body1" paragraph>
            As default report period the last five years are taken. As default
            setting all customized EQS evaluations are included in the reports.
          </Typography>
          <ReportsForm
            className="reports-form"
            data={values}
            onSubmit={setValues}
            stationCode={params.stationCode}
          />
        </ActionBarDrawerContent>
      </StyledActionBarDrawer>
    );
  }
}

const StyledActionBarDrawer = styled(ActionBarDrawer)`
  .reports-form {
    padding: ${({ theme }) => theme.spacing(1, 0, 5, 0)};
  }
`;
