import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const ButtonGrid = styled(Grid)`
  display: flex;

  > *:not(:first-child) {
    margin-left: ${({ theme }) => `${theme.spacing(1)}px`};
  }
`;
