import { Popup } from "react-leaflet";
import { Button, Typography } from "@material-ui/core";

import { Station } from "../types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export function StationPopup(props: {
  station: Station;
  onShowDetails: () => void;
  onClose: () => void;
}) {
  const { station } = props;
  const { t } = useTranslation();

  return (
    <StyledPopup autoPan={false}>
      <header>
        <Typography variant="h2"> {station.code}</Typography>
        <Typography variant="h4" gutterBottom>
          {station.communityName}
        </Typography>
      </header>

      <footer>
        <Button
          variant="text"
          color="default"
          size="small"
          onClick={props.onShowDetails}
        >
          {t("label.showDetails")}
        </Button>
      </footer>
    </StyledPopup>
  );
}

const StyledPopup = styled(Popup)`
  .leaflet-popup-content-wrapper {
    border-radius: 4px;
  }

  header {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
  }

  footer {
    margin: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;
