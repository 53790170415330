import { InputAdornment } from "@material-ui/core";
import { TFunction } from "i18next";

export function adornments(t: TFunction) {
  return {
    microgramPerLiter: {
      endAdornment: (
        <InputAdornment position="end">
          {t("adornments.microgramPerLiter")}
        </InputAdornment>
      ),
    },
  };
}
