import { Standard, LegendStandardType, Station, Statistic } from "../types";
import * as LegendStandardUtils from "../utils/legend-standard.utils";

export function getValue(station: Station, statistic: Statistic) {
  switch (statistic.name) {
    case "TWA":
      return station.average;
    case "AMMA":
      return station.arithmeticMeanMonthlyAverage;
    case "MAX":
      return station.maximum;
    default:
      console.warn(`Unknown statistic ${statistic.name}`);
      return null;
  }
}

export function getColor(
  station: Station,
  standard: Standard,
  statistic: Statistic
) {
  const fallbackColor = "hotpink";
  const value = getValue(station, statistic);

  if (value === null) {
    // if no value, return no NO_ASSESMENT_POSSIBLE type
    const legendItem = LegendStandardUtils.getLegendType(
      standard.legend,
      LegendStandardType.NO_ASSESMENT_POSSIBLE
    );
    if (legendItem) return legendItem.color;
    // or return fallback color...
    else return fallbackColor;
  }

  // if value equals LOQ, return LOWER_THEN_LOQ type
  if (station.measurementCount === station.loqMeasurementCount) {
    const legendItem = LegendStandardUtils.getLegendType(
      standard.legend,
      LegendStandardType.LOWER_THEN_LOQ
    );
    if (legendItem) return legendItem.color;

    // else continue, this happens when source is NOT surface water
  }

  // fetch normal legend item
  const legendItem = LegendStandardUtils.getLegendItem(value, standard.legend);
  if (legendItem) return legendItem.color;
  else return fallbackColor;
}
