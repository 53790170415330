import {
  createMuiTheme,
  ThemeOptions,
  SimplePaletteColorOptions,
  unstable_createMuiStrictModeTheme,
} from "@material-ui/core";
import { fontFaces } from "./fonts";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    actionBar: {
      width: React.CSSProperties["width"];
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    actionBar?: {
      width: React.CSSProperties["width"];
    };
  }
}

interface TextColors {
  primary: string;
  secondary: string | undefined;
  disabled: string;
  hint: string;
}

const createTheme =
  process.env.NODE_ENV === "production"
    ? createMuiTheme
    : unstable_createMuiStrictModeTheme;

export const theme = ((options: ThemeOptions) => {
  const borderRadius = 18;

  const primaryColor: SimplePaletteColorOptions = {
    light: "rgba(149, 209, 255, 1)",
    main: "rgba(62, 73, 96, 1)",
    dark: "rgba(0, 46, 86, 1)",
    contrastText: "#fff",
  };

  const secondaryColor: SimplePaletteColorOptions = {
    light: "rgba(255, 195, 143, 1)",
    main: "rgba(245, 130, 32, 1)",
    dark: "rgba(216, 99, 1, 1)",
    contrastText: "#fff",
  };

  const successColor: SimplePaletteColorOptions = {
    light: "#c7fc55",
    main: "#93C918",
    dark: "#609800",
    contrastText: "#000",
  };

  const errorColor: SimplePaletteColorOptions = {
    light: "rgba(242, 138, 138, 1)",
    main: "rgba(255, 71, 71, 1)",
    dark: "rgba(148, 0, 0, 1)",
    contrastText: "#fff",
  };

  const warning = secondaryColor;

  const text: TextColors = {
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)",
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)",
  };

  const SPACING = 8;

  // inspired by https://systemfontstack.com
  const fallback =
    " -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif";
  const avenirFont = `Avenir, ${fallback}`;

  return createTheme({
    spacing: SPACING,
    actionBar: {
      width: 60,
    },
    palette: {
      primary: primaryColor,
      secondary: secondaryColor,
      text,
      success: successColor,
      warning,
      error: errorColor,
      background: {
        paper: "#FFF",
        default: "#F7FAFF",
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: avenirFont,

      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 800,
      h1: {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: 0,
        color: "#000",
      },
      h2: {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0,
        color: "#000",
      },
      h3: {
        fontWeight: 600,
        fontSize: 15,
        letterSpacing: 0,
        color: "#000",
      },
      h4: {
        fontWeight: 300,
        fontSize: 14,
        letterSpacing: 0,
        color: primaryColor.dark,
      },
      h5: {
        fontWeight: 900,
        fontSize: 13,
        letterSpacing: 0,
        color: "#000",
        fontStyle: "normal",
      },
      h6: {
        fontWeight: 700,
        fontSize: 12,
        letterSpacing: 0,
        color: "#000",
      },
      body1: {
        color: "#000",
        fontWeight: 400,
        fontSize: 14,
      },
      body2: {
        color: primaryColor.main,
        fontWeight: 400,
      },
    },
    shape: {
      borderRadius: 0,
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": fontFaces,
        },
      },

      MuiAppBar: {
        colorPrimary: {
          backgroundColor: "rgb(62, 73, 96)",
          color: "#F7F7F7",
          contrastText: "#F7F7F7",
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: Math.round(borderRadius / 6),
        },
        root: {},
      },
      MuiOutlinedInput: {
        input: {
          backgroundColor: "white",
        },
      },
      MuiButton: {
        containedPrimary: {
          borderRadius: Math.round(borderRadius),
        },
      },
      MuiListSubheader: {
        root: {
          lineHeight: 1.4,
          paddingTop: SPACING * 2,
          paddingBottom: SPACING * 2,
        },
      },
    },
    ...options,
  });
})({});
