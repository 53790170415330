import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSources } from "../../hooks";
import { Source, Substance } from "../../types";

interface Props {
  value: Source["id"] | null;
  onChange: (source: Source) => void;
  substanceSymbol: Substance["symbol"];
}

export function SourceSelect(props: Props) {
  const { t } = useTranslation();
  const { data: sources } = useSources(props.substanceSymbol);

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    if (!sources) return;

    const match = sources.find((source) => source.id === event.target.value);
    if (match) props.onChange(match);
  }

  // render the component
  return render();

  function render() {
    if (!sources) return null;

    return (
      <FormControl fullWidth>
        <InputLabel>{t("label.source")}</InputLabel>

        <Select value={props.value || ""} onChange={handleChange}>
          {sources.map((source) => (
            <MenuItem key={source.id} value={source.id}>
              {t(`label.sourceKey.${source.name}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
