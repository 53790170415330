import { MenuItem, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { useSurfaceWaterStandards } from "../../hooks";
import { DEFAULT, Standard, Substance } from "../../types";

interface GenericForm {
  substanceSymbol: Substance["symbol"] | "";
  surfaceWaterStandard: Standard["id"];
}

export function SurfaceWaterStandardSelect<T extends GenericForm>(props: {}) {
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<T>();
  const { t } = useTranslation();
  const { data: surfaceWaterStandards } = useSurfaceWaterStandards(
    values.substanceSymbol,
    {
      enabled: !!values.substanceSymbol,
    }
  );
  return render();

  function render() {
    if (!surfaceWaterStandards) return null;

    return (
      <TextField
        id="surfaceWaterStandard"
        name="surfaceWaterStandard"
        label={t("label.surfaceWaterStandard")}
        type="text"
        select={true}
        fullWidth
        value={values.surfaceWaterStandard}
        onBlur={handleBlur}
        onChange={handleChange}
        error={
          touched.surfaceWaterStandard && Boolean(errors.surfaceWaterStandard)
        }
        helperText={touched.surfaceWaterStandard && errors.surfaceWaterStandard}
      >
        <MenuItem value={DEFAULT}>Default</MenuItem>
        {surfaceWaterStandards.map((standard) => (
          <MenuItem
            key={["surface-water-standard", standard.id].join()}
            value={standard.id}
          >
            {standard.name}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}
