import { debounce } from "lodash-es";
import { useMap } from "react-leaflet";

export const MapSizeObserver = () => {
  const map = useMap();
  const ro = new ResizeObserver(
    debounce(() => {
      map.invalidateSize({ animate: true });
    }, 500)
  );
  ro.observe(map.getContainer());
  return null;
};
