import {
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useStation } from "../hooks";
import styled from "styled-components";
import { StationDetailTable } from "./StationDetailTable";
import { StationLandUseDistribution } from "./StationLandUseDistribution/StationLandUseDistribution";
import { StationMeasurementGraph } from "./StationMeasurementGraph";
import { useTranslation } from "react-i18next";

interface Props {
  substanceSymbol: string;
  sourceId: number;
  year: number;
  stationCode: string;
  onClose: () => void;
}

export function StationDetail(props: Props) {
  const { t } = useTranslation();
  const { data: station } = useStation(
    props.substanceSymbol,
    props.sourceId,
    props.year,
    props.stationCode
  );

  // render the component
  return render();

  function render() {
    if (!station) return null;

    return (
      <StyledDialog open onClose={props.onClose} maxWidth="md">
        <DialogTitle disableTypography>
          <Typography variant="h1">
            {station.code} - {station.name} - {station.communityName}
          </Typography>
          <IconButton
            aria-label="close"
            className="close-button"
            onClick={props.onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                {t("label.stationDetails")}
              </Typography>
              <StationDetailTable
                substanceSymbol={props.substanceSymbol}
                sourceId={props.sourceId}
                year={props.year}
                stationCode={props.stationCode}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                {t("label.timeseries")}
              </Typography>
              <StationMeasurementGraph
                className="graph"
                substanceSymbol={props.substanceSymbol}
                sourceId={props.sourceId}
                year={props.year}
                stationCode={props.stationCode}
                height={400}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                {t("label.landuse")}
              </Typography>
              <StationLandUseDistribution
                substanceSymbol={props.substanceSymbol}
                sourceId={props.sourceId}
                year={props.year}
                stationCode={props.stationCode}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </StyledDialog>
    );
  }
}

const StyledDialog = styled(Dialog)`
  /* width: 100%; */
  /* max-width: 800px; */
  height: 100%;

  .close-button {
    position: absolute;
    right: ${({ theme }) => theme.spacing(1)}px;
    top: ${({ theme }) => theme.spacing(1)}px;
    color: ${({ theme }) => theme.palette.grey[500]};
  }
`;
