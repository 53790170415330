import { Card, CardHeader, CardContent } from "@material-ui/core";

import { OverlayerWmsLayer } from "../../types";
import { getLegendGraphic } from "../../utils/wms.utils";

export function LegendOverlayer(props: { overlayer: OverlayerWmsLayer }) {
  const { overlayer } = props;

  return (
    <Card elevation={0}>
      <CardHeader title={overlayer.title} />
      <CardContent>
        <img
          key={overlayer.wmsLayer.layers!}
          src={getLegendGraphic(overlayer.wmsLayer.layers!)}
          alt={`legend kaart ${overlayer.wmsLayer.layers!}`}
        />
      </CardContent>
    </Card>
  );
}
