import { Table } from "@material-ui/core";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  .MuiTableCell-root:first-child {
    padding-left: 0;
  }
  .MuiTableCell-root {
    padding: ${({ theme }) => theme.spacing(0.5, 1)};
  }
  .MuiTableCell-head {
    font-weight: 100;
    font-style: italic;
  }
`;
