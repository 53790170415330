import { Suspense } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { StationDetail } from "../components/StationDetail";
import { useParameterSelection } from "../hooks/useParamaterSelection";

interface Props {}

export function StationDetailPage(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ stationCode: string }>();
  const { selection } = useParameterSelection();

  function handleClose() {
    history.push({
      pathname: `/stations/${params.stationCode}`,
      search: location.search,
    });
  }

  return render();

  function render() {
    if (!selection.substanceSymbol || !selection.sourceId || !selection.year) {
      console.warn("Oh oh, no param selection");

      return null;
    }

    return (
      <Suspense fallback={null}>
        <StationDetail
          substanceSymbol={selection.substanceSymbol}
          sourceId={selection.sourceId}
          year={selection.year}
          stationCode={params.stationCode}
          onClose={handleClose}
        />
      </Suspense>
    );
  }
}
