import { Suspense, useMemo } from "react";
import { Circle } from "react-leaflet";
import { WMSTileLayer } from "react-leaflet";
import { useParams } from "react-router-dom";

import { useSources, useStation } from "../hooks";
import { useParameterSelection } from "../hooks/useParamaterSelection";

export function ActiveStationLayers() {
  const params = useParams<{ stationCode: string }>();
  const { selection } = useParameterSelection();
  return render();

  function render() {
    if (!selection.substanceSymbol) return null;
    if (!selection.sourceId) return null;
    if (!selection.year) return null;
    if (!params.stationCode) return null;

    return (
      <Suspense fallback={null}>
        <Layers
          substanceSymbol={selection.substanceSymbol}
          sourceId={selection.sourceId}
          year={selection.year}
          stationCode={params.stationCode}
        />
      </Suspense>
    );
  }
}

function Layers(props: {
  substanceSymbol: string;
  sourceId: number;
  year: number;
  stationCode: string;
}) {
  const { data: sources } = useSources(props.substanceSymbol);
  const { data: station } = useStation(
    props.substanceSymbol,
    props.sourceId,
    props.year,
    props.stationCode
  );

  const source = useMemo(
    () => sources?.find((source) => source.id === station?.source),
    [sources, station?.source]
  );

  return render();

  function render() {
    if (!station) return null;
    if (!source) return null;

    if (source.name === "SW") {
      // render drainage basins when source is Surface Water
      return (
        <WMSTileLayer
          key={station.waterBodyCodes.join()}
          url="/geoserver/wms"
          layers="waterprotect:drainage_basins"
          styles="waterprotect:drainage_basin_for_station"
          transparent
          format="image/png"
          zIndex={2}
          // TODO: add this to WMSTileLayer type
          // @ts-ignore
          cql_filter={station.waterBodyCodes
            .map((code) => `WBcode_xx = '${code}'`)
            .join(" or ")}
        />
      );
    } else {
      // else wise render a 1km buffer
      return (
        <Circle
          center={[station.lat, station.lon]}
          radius={1000}
          pathOptions={{ color: "#FF7800" }}
        />
      );
    }
  }
}
