export function getLegendGraphic(layer: string): string {
  const data = {
    version: "1.1.0",
    request: "GetLegendGraphic",
    layer,
    format: "image/png",
    legend_options: "forceLabels:on",
  };
  const searchParams = new URLSearchParams(data);

  return `/geoserver/gwc/service/wms?${searchParams}`;
}
