import { MenuItem, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { useSubstances } from "../../hooks";
import { Substance } from "../../types";

interface GenericForm {
  substanceSymbol: Substance["symbol"] | "";
}
export function SubstanceSelect<T extends GenericForm>(props: {}) {
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<T>();
  const { t } = useTranslation();
  const { data: substances } = useSubstances();
  return render();

  function render() {
    if (!substances) return null;

    return (
      <TextField
        id="substanceSymbol"
        className="parent-clickaway-ignore"
        name="substanceSymbol"
        label={t("label.substance")}
        type="text"
        select={true}
        fullWidth
        value={values.substanceSymbol}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.substanceSymbol && Boolean(errors.substanceSymbol)}
        helperText={touched.substanceSymbol && errors.substanceSymbol}
      >
        {substances.map((substance) => (
          <MenuItem key={substance.symbol} value={substance.symbol}>
            {substance.name}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}
