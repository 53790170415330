import { Portal } from '@material-ui/core';
import { createControlComponent } from '@react-leaflet/core';
import { Control, ControlOptions, DomEvent, DomUtil } from 'leaflet';
import { ReactNode, useState } from 'react';

interface Props extends ControlOptions {
  children: ReactNode;
}

export const MapControlBase = (props: Props) => {
  const { children, ...controlProps } = props;
  const [isAdded, setIsAdded] = useState(false);
  const [container] = useState<HTMLElement>(DomUtil.create('div', 'custom-control'));

  return (
    <>
      <ControlContainer {...controlProps} container={container} setIsAdded={setIsAdded}></ControlContainer>
      {isAdded && <Portal container={container}>{children}</Portal>}
    </>
  );
};

interface ControlContainerProps extends ControlOptions {
  container: HTMLElement;
  setIsAdded: (value: boolean) => void;
}

const ControlContainer = createControlComponent<Control, ControlContainerProps>((props) => {
  const { container, setIsAdded, ...forwardProps } = props;
  const ForwardControl = Control.extend({
    onAdd: () => {
      setIsAdded(true);
      DomEvent.disableClickPropagation(container);
      return container;
    },
    onRemove: () => {
      setIsAdded(false);
    },
  });
  return new ForwardControl(forwardProps);
});
