import { round } from "lodash-es";

export function pct(num: number, total: number) {
  return (num / total) * 100;
}

export function formatHa(value: number, precision = 2) {
  return round(value, precision).toFixed(precision) + " ha";
}

export function formatPct(value: number, precision = 2) {
  return round(value, precision).toFixed(precision) + "%";
}
