import { Suspense } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";

import { MapPosition } from "../../types";
import { useParameterSelection } from "../../hooks/useParamaterSelection";
import { SubstanceSelect } from "./SubstanceSelect";
import { SourceSelect } from "./SourceSelect";
import { YearSelect } from "./YearSelect";
import { StandardSelect } from "./StandardSelect";
import { StatisticSelect } from "./StatisticSelect";
import { pick } from "lodash-es";

export function mapPositionFromEnv(): MapPosition {
  const [lat, lon, zoom] = window._env_.REACT_APP_INITIAL_POSITION!.split(",");
  return { lat: parseFloat(lat), lon: parseFloat(lon), zoom: parseInt(zoom) };
}

interface Props {}

export function ParameterSelection(props: Props) {
  const { selection, setSelection } = useParameterSelection();

  return render();

  function render() {
    return (
      <Styles>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Suspense fallback="...">
              <SubstanceSelect
                value={selection.substanceSymbol}
                onChange={(substanceSymbol) => {
                  setSelection({
                    substanceSymbol,
                  });
                }}
              />
            </Suspense>
          </Grid>
          {selection.substanceSymbol && (
            <Grid item xs={12}>
              <Suspense fallback="...">
                <SourceSelect
                  substanceSymbol={selection.substanceSymbol}
                  value={selection.sourceId}
                  onChange={(source) => {

                    setSelection({
                      ...pick(selection, "substanceSymbol"),
                      sourceId: source.id,
                      sourceCategoryId: source.sourceCategoryId,
                    });
                  }}
                />
              </Suspense>
            </Grid>
          )}
          {selection.substanceSymbol && selection.sourceId && (
            <Grid item xs={12}>
              <Suspense fallback="...">
                <YearSelect
                  substanceSymbol={selection.substanceSymbol}
                  sourceId={selection.sourceId}
                  value={selection.year}
                  onChange={(year) =>
                    setSelection({
                      ...pick(selection, [
                        "substanceSymbol",
                        "sourceId",
                        "sourceCategoryId",
                      ]),
                      year,
                    })
                  }
                />
              </Suspense>
            </Grid>
          )}

          {selection.substanceSymbol && selection.sourceId && selection.year && (
            <Grid item xs={12}>
              <Suspense fallback="...">
                <StandardSelect
                  substanceSymbol={selection.substanceSymbol}
                  sourceId={selection.sourceId}
                  value={selection.standardId}
                  onChange={(standardId) =>
                    setSelection({
                      ...pick(selection, [
                        "substanceSymbol",
                        "sourceId",
                        "sourceCategoryId",
                        "year",
                      ]),
                      standardId,
                    })
                  }
                />
              </Suspense>
            </Grid>
          )}

          {selection.standardId && (
            <Grid item xs={12}>
              <Suspense fallback="...">
                <StatisticSelect
                  standardId={selection.standardId}
                  value={selection.statisticId}
                  onChange={(statisticId) =>
                    setSelection({
                      ...pick(selection, [
                        "substanceSymbol",
                        "sourceId",
                        "sourceCategoryId",
                        "year",
                        "standardId",
                      ]),
                      statisticId,
                    })
                  }
                />
              </Suspense>
            </Grid>
          )}
        </Grid>
      </Styles>
    );
  }
}

const Styles = styled.div`
  padding: ${({ theme }) => theme.spacing(3) + "px"};
`;
