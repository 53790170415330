import { Box, Typography, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import styled from "styled-components";

export function ActionBarDrawerHeader(props: {
  title: string;
  onClose: () => void;
}) {
  return (
    <Styles>
      <Typography variant="h1" gutterBottom>
        {props.title}
      </Typography>
      <IconButton size="small" onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
    </Styles>
  );
}

const Styles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;
