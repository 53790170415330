import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useYears } from "../../hooks";
import { Source, Substance, Year } from "../../types";

interface Props {
  value: Year["year"] | null;
  onChange: (year: Year["year"] | null) => void;
  substanceSymbol: Substance["symbol"];
  sourceId: Source["id"];
}

export function YearSelect(props: Props) {
  const { t } = useTranslation();
  const { data: years } = useYears(props.substanceSymbol, props.sourceId);

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    props.onChange(event.target.value as number);
  }

  return render();
  function render() {
    if (!years) return null;

    return (
      <FormControl fullWidth>
        <InputLabel>{t("label.year")}</InputLabel>

        <Select value={props.value || ""} onChange={handleChange}>
          {years.map((year) => (
            <MenuItem key={year.year} value={year.year}>
              {year.year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
