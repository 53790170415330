import { ReportsFormData } from "../forms/ReportsForm/ReportsForm";
import {
  ReportArea,
  ReportType,
  Source,
  Station,
  SubstanceSourceCategories,
} from "../types";
import {
  getReportLinks,
  surfaceWaterSourceCategoryId,
  groundWaterSourceCategoryId,
  hasSurfaceWaterIn,
  hasGroundWaterIn,
} from "../utils/report.utils";

export function getLocationReports(
  formData: ReportsFormData,
  stationCode: Station["code"],
  sourceCategoryId: Source["sourceCategoryId"]
) {
  return [
    {
      text: `Location report for ${formData.substanceSymbol}`,
      href: getReportLinks({
        symbol: formData.substanceSymbol,
        sourceCategoryId,
        standardId: formData.surfaceWaterStandard,
        fromYear: formData.from,
        toYear: formData.to,
        area: ReportArea.belgium,
        type: ReportType.location,
        station: stationCode,
      }),
    },
  ];
}

export function getRegionReports(
  formData: ReportsFormData,
  sourceCategories: SubstanceSourceCategories
) {
  const countryReports = [
    ...(hasSurfaceWaterIn(ReportArea.belgium, sourceCategories)
      ? [
          {
            text: `Country report for ${formData.substanceSymbol} in surface water (Belgium)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: surfaceWaterSourceCategoryId,
              standardId: formData.surfaceWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.belgium,
              type: ReportType.country,
            }),
          },
        ]
      : []),
    ...(hasGroundWaterIn(ReportArea.belgium, sourceCategories)
      ? [
          {
            text: `Country report for ${formData.substanceSymbol} in groundwater (Belgium)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: groundWaterSourceCategoryId,
              standardId: formData.groundWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.belgium,
              type: ReportType.country,
            }),
          },
        ]
      : []),
    ...(hasSurfaceWaterIn(ReportArea.flanders, sourceCategories)
      ? [
          {
            text: `Region report for ${formData.substanceSymbol} in surface water (Flanders)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: surfaceWaterSourceCategoryId,
              standardId: formData.surfaceWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.flanders,
              type: ReportType.country,
            }),
          },
        ]
      : []),
    ...(hasGroundWaterIn(ReportArea.flanders, sourceCategories)
      ? [
          {
            text: `Region report for ${formData.substanceSymbol} in groundwater (Flanders)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: groundWaterSourceCategoryId,
              standardId: formData.groundWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.flanders,
              type: ReportType.country,
            }),
          },
        ]
      : []),
    ...(hasSurfaceWaterIn(ReportArea.wallonia, sourceCategories)
      ? [
          {
            text: `Region report for ${formData.substanceSymbol} in surface water (Wallonia)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: surfaceWaterSourceCategoryId,
              standardId: formData.surfaceWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.wallonia,
              type: ReportType.country,
            }),
          },
        ]
      : []),
    ...(hasGroundWaterIn(ReportArea.wallonia, sourceCategories)
      ? [
          {
            text: `Region report for ${formData.substanceSymbol} in groundwater (Wallonia)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: groundWaterSourceCategoryId,
              standardId: formData.groundWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.wallonia,
              type: ReportType.country,
            }),
          },
        ]
      : []),
  ];
  const exeedanceReports = [
    ...(hasSurfaceWaterIn(ReportArea.belgium, sourceCategories)
      ? [
          {
            text: `Exeedance report for ${formData.substanceSymbol} in surface water (Belgium)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: surfaceWaterSourceCategoryId,
              standardId: formData.surfaceWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.belgium,
              type: ReportType.exceedance,
            }),
          },
        ]
      : []),
    ...(hasGroundWaterIn(ReportArea.belgium, sourceCategories)
      ? [
          {
            text: `Exeedance report for ${formData.substanceSymbol} in groundwater (Belgium)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: groundWaterSourceCategoryId,
              standardId: formData.groundWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.belgium,
              type: ReportType.exceedance,
            }),
          },
        ]
      : []),
    ...(hasSurfaceWaterIn(ReportArea.flanders, sourceCategories)
      ? [
          {
            text: `Exeedance report for ${formData.substanceSymbol} in surface water (Flanders)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: surfaceWaterSourceCategoryId,
              standardId: formData.surfaceWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.flanders,
              type: ReportType.exceedance,
            }),
          },
        ]
      : []),
    ...(hasGroundWaterIn(ReportArea.flanders, sourceCategories)
      ? [
          {
            text: `Exeedance report for ${formData.substanceSymbol} in groundwater (Flanders)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: groundWaterSourceCategoryId,
              standardId: formData.groundWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.flanders,
              type: ReportType.exceedance,
            }),
          },
        ]
      : []),
    ...(hasSurfaceWaterIn(ReportArea.wallonia, sourceCategories)
      ? [
          {
            text: `Exeedance report for ${formData.substanceSymbol} in surface water (Wallonia)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: surfaceWaterSourceCategoryId,
              standardId: formData.surfaceWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.wallonia,
              type: ReportType.exceedance,
            }),
          },
        ]
      : []),
    ...(hasGroundWaterIn(ReportArea.wallonia, sourceCategories)
      ? [
          {
            text: `Exeedance report for ${formData.substanceSymbol} in groundwater (Wallonia)`,
            href: getReportLinks({
              symbol: formData.substanceSymbol,
              sourceCategoryId: groundWaterSourceCategoryId,
              standardId: formData.groundWaterStandard,
              fromYear: formData.from,
              toYear: formData.to,
              area: ReportArea.wallonia,
              type: ReportType.exceedance,
            }),
          },
        ]
      : []),
  ];

  return [...countryReports, ...exeedanceReports];
}
