import { APIError } from "../types";
import { i18n } from "../i18n";
import { startCase } from "lodash-es";

/**
 * Replace all replacements with argumentw index positioned
 *
 * Example:
 * parseServerErrror('Er is geen geïnterpoleerde kaart voor {1} in {0}. (lokaal)', ["Grondwater", "Arseen"]);
 *
 * Result: Er is geen geïnterpoleerde kaart voor Arseen in Grondwater. (lokaal).
 *
 * @param error ServerError
 */
export function parseServerError(error: APIError): string {
  return error.arguments.reduce(
    (newMessage: string, arg: string, argIndex: number): string => {
      return newMessage.replaceAll(`{${argIndex}}`, arg);
    },
    error.message
  );
}
export function argumentsToObject(args: string[]) {
  return args.reduce((obj, item, index) => {
    return { ...obj, [index.toString()]: item };
  }, {});
}

export function minMaxToString(minmax: { min?: number; max?: number }) {
  const { min, max } = minmax;
  const minLabel = startCase(i18n.t("label.min"));
  const maxLabel = startCase(i18n.t("label.max"));

  if (min && max) return `${minLabel}: ${min}, ${maxLabel}: ${max}`;
  if (min) return `${minLabel}: ${min}`;
  if (max) return `${maxLabel}: ${max}`;
  if (minmax === null) return "";
}

export function sridToString(srid: string | number) {
  return `EPSG:${srid}`;
}
