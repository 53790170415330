import axios from "axios";
import { auth } from "marvin-auth-kit";

let interceptor: any;

// I18n interception
export function i18nInterceptor(language: string) {
  // first eject old interceptor
  if (interceptor) {
    axios.interceptors.request.eject(interceptor);
  }

  // i18n interceptor
  interceptor = axios.interceptors.request.use((config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        "Accept-Language": language,
      },
    };
  });
}

auth.applyInterceptors(axios);
