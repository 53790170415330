import { WMSTileLayerProps } from "react-leaflet";
import { Overlayer, OverlayerWithYearVariants } from "../types";

const baseWmsLayer: WMSTileLayerProps = {
  url: "/geoserver/gwc/service/wms",
  transparent: true,
  opacity: 0.7,
  format: "image/png",
};

const landuseFlanders: OverlayerWithYearVariants = {
  key: "landuse-flanders",
  title: "Landuse Flanders",
  wmsLayer: { ...baseWmsLayer, minZoom: 12 },
  variants: [
    { year: 2008, layers: "waterprotect:landuse2008" },
    { year: 2009, layers: "waterprotect:landuse2009" },
    { year: 2010, layers: "waterprotect:landuse2010" },
    { year: 2011, layers: "waterprotect:landuse2011" },
    { year: 2012, layers: "waterprotect:landuse2012" },
    { year: 2013, layers: "waterprotect:landuse2013" },
    { year: 2014, layers: "waterprotect:landuse2014" },
    { year: 2015, layers: "waterprotect:landuse2015" },
    { year: 2016, layers: "waterprotect:landuse2016" },
    { year: 2017, layers: "waterprotect:landuse2017" },
    { year: 2018, layers: "waterprotect:landuse2018" },
    { year: 2019, layers: "waterprotect:landuse2019" },
    { year: 2020, layers: "waterprotect:landuse2020" },
    { year: 2021, layers: "waterprotect:landuse2021" },
  ],
};

const landuseWallonia: OverlayerWithYearVariants = {
  key: "landuse-wallonia",
  title: "Landuse Wallonia",
  wmsLayer: { ...baseWmsLayer, minZoom: 12 },
  variants: [
    { year: 2015, layers: "waterprotect:landusewal2015" },
    { year: 2016, layers: "waterprotect:landusewal2016" },
    { year: 2017, layers: "waterprotect:landusewal2017" },
    { year: 2018, layers: "waterprotect:landusewal2018" },
    { year: 2019, layers: "waterprotect:landusewal2019" },
    { year: 2020, layers: "waterprotect:landusewal2020" },
    { year: 2021, layers: "waterprotect:landusewal2021" },
  ],
};

const erosionRiskFlanders: OverlayerWithYearVariants = {
  key: "erosion-risk-flanders",
  title: "Erosion risk flanders",
  wmsLayer: { ...baseWmsLayer, minZoom: 12 },
  variants: [
    {
      year: 2008,
      layers: "waterprotect:erosion2008",
    },
    {
      year: 2009,
      layers: "waterprotect:erosion2009",
    },
    {
      layers: "waterprotect:erosion2015",
      year: 2015,
    },
    {
      layers: "waterprotect:erosion2016",
      year: 2016,
    },
    {
      layers: "waterprotect:erosion2017",
      year: 2017,
    },
    {
      layers: "waterprotect:erosion2018",
      year: 2018,
    },
    {
      layers: "waterprotect:erosion2019",
      year: 2019,
    },
    {
      layers: "waterprotect:erosion2020",
      year: 2020,
    },
    {
      layers: "waterprotect:erosion2021",
      year: 2021,
    },
  ],
};

export const overlayers: Array<Overlayer | OverlayerWithYearVariants> = [
  erosionRiskFlanders,
  landuseFlanders,
  landuseWallonia,
  {
    key: "subbassins",
    title: "Subbassins",
    layers: "waterprotect:subbasins",
    wmsLayer: { ...baseWmsLayer },
  },
  {
    key: "water_courses",
    title: "Water courses",
    layers: "waterprotect:water_courses",
    wmsLayer: { ...baseWmsLayer },
  },
  {
    key: "drainage_basins",
    title: "Drainage basins",
    layers: "waterprotect:drainage_basins",
    wmsLayer: { ...baseWmsLayer },
  },
];
