import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStandards } from "../../hooks";
import { Source, Standard, Substance } from "../../types";

interface Props {
  value: Standard["id"] | null;
  onChange: (standard: Standard["id"] | null) => void;
  substanceSymbol: Substance["symbol"];
  sourceId: Source["id"];
}

export function StandardSelect(props: Props) {
  const { t } = useTranslation();
  const { data: standards } = useStandards(
    props.substanceSymbol,
    props.sourceId
  );

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    props.onChange(event.target.value as number);
  }

  // render the component
  return render();

  function render() {
    if (!standards) return null;

    return (
      <FormControl fullWidth>
        <InputLabel>{t("label.standard")}</InputLabel>

        <Select value={props.value || ""} onChange={handleChange}>
          {standards.map((standard) => (
            <MenuItem key={standard.id} value={standard.id}>
              {standard.name} ({standard.standardValue} µg/l)
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
