import clsx from "clsx";
import { PlotData } from "plotly.js";
import { useMemo } from "react";
import Plot from "react-plotly.js";
import styled from "styled-components";
import { useStation } from "../hooks";
import { Station } from "../types";

interface Props {
  substanceSymbol: string;
  sourceId: number;
  year: number;
  stationCode: string;
  className?: string;
  height: number;
}

const X_AXIS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function getMeasurementByMonth(
  measurements: Station["measurements"],
  monthIndex: number
) {
  return measurements.find(
    (measurement) => measurement.month === monthIndex + 1
  );
}

const defaultProps = { height: 500 };
export function StationMeasurementGraph(props: Props) {
  const { substanceSymbol, sourceId, year, stationCode } = props;
  const { data: station } = useStation(
    substanceSymbol,
    sourceId,
    year,
    stationCode
  );

  const plotData = useMemo(() => {
    if (!station) return [];
    return measurementsToGraphData(station);
  }, [station]);

  return render();

  function render() {
    if (!station) return null;

    return (
      <Styles className={clsx(props.className)} height={props.height}>
        <Plot
          data={plotData}
          style={{ width: "100%", height: "100%" }}
          layout={{
            autosize: true,
            legend: { orientation: "h" },
            barmode: "overlay",
            xaxis: { automargin: true, zeroline: true },
            yaxis: {
              automargin: true,
              zeroline: false,
              ticksuffix: "µg/l",
              hoverformat: ".4f",
            },
            showlegend: true,
            margin: { t: 20, b: 0, l: 0, r: 0 },
          }}
        />
      </Styles>
    );
  }
}
StationMeasurementGraph.defaultProps = defaultProps;

const Styles = styled.div<{ height: number }>`
  position: relative;
  width: 100%;
  height: ${(props) => props.height}px;
`;

function measurementsToGraphData(station: Station) {
  const { measurements } = station;

  const monthlyAverageTrace: Partial<PlotData> = {
    type: "bar",
    marker: { color: "rgb(162, 185, 102)" },
    name: "monthly average",
    x: X_AXIS,
    y: X_AXIS.map((_, monthIndex) => {
      const measurement = getMeasurementByMonth(measurements, monthIndex);

      if (measurement) {
        if (measurement.monthlyAverage !== measurement.reportingLimit / 2) {
          return measurement.monthlyAverage;
        }
      }
      return null;
    }),
  };

  const halfReportingLimitTrace: Partial<PlotData> = {
    type: "bar",
    marker: { color: "rgb(123,103,186)" },
    name: "1/2 reporting limit",
    x: X_AXIS,
    y: X_AXIS.map((_, monthIndex) => {
      const measurement = getMeasurementByMonth(measurements, monthIndex);

      if (measurement) {
        if (measurement.monthlyAverage === measurement.reportingLimit / 2) {
          return measurement.monthlyAverage;
        }
      }
      return null;
    }),
  };

  const monthlyMaxTrace: Partial<PlotData> = {
    type: "scatter",
    mode: "markers",
    marker: { color: "rgb(101, 170, 195)", symbol: "triangle-up", size: 12 },

    name: "monthly maximum",
    x: X_AXIS,
    y: X_AXIS.map((_, monthIndex) => {
      const measurement = getMeasurementByMonth(measurements, monthIndex);

      if (measurement) {
        if (measurement.valueCount > 1) {
          return measurement.monthlyMaximum;
        }
      }
      return null;
    }),
  };

  const tmaTrace: Partial<PlotData> = {
    mode: "lines",
    name: "TWA",
    x: X_AXIS,
    y: X_AXIS.map((_) => station.average),
    line: {
      dash: "dash",
      color: "rgb(103, 140, 190)",
    },
  };

  const ammaTrace: Partial<PlotData> = {
    mode: "lines",
    name: "AMMA",
    x: X_AXIS,
    y: X_AXIS.map((_) => station.arithmeticMeanMonthlyAverage),
    line: {
      dash: "dot",
      color: "rgb(184, 101, 95)",
    },
  };

  return [
    ...(station.average ? [tmaTrace] : []),
    ...(station.arithmeticMeanMonthlyAverage ? [ammaTrace] : []),
    monthlyAverageTrace,
    halfReportingLimitTrace,
    monthlyMaxTrace,
  ];
}
