import { Suspense, useEffect, useMemo } from "react";
import { Drawer, Toolbar } from "@material-ui/core";
import styled from "styled-components";
import {
  MapContainer,
  TileLayer,
  ScaleControl,
  WMSTileLayer,
  useMap as useLeafletMap,
} from "react-leaflet";
import { Route } from "react-router";

import { useMapPosition, mapPositionFromEnv } from "../hooks/useMapPosition";
import { useParameterSelection } from "../hooks/useParamaterSelection";
import { AppLayout } from "../layouts/AppLayout";
import { ActiveStationLayers } from "../components/ActiveStationLayers";
import { PersistMapPosition } from "../components/map/PersistMapPosition";
import { MapZoomControls } from "../components/map/MapZoomControls";
import { ParameterSelection } from "../components/ParameterSelection/ParameterSelection";
import { StationsOnMap } from "../components/StationsOnMap";
import { StationsSummary } from "../components/StationsSummary/StationsSummary";
import { ActionBar } from "../components/ ActionBar/ActionBar";
import { MapControlBase } from "../components/map/MapControlBase";
import { Legend } from "../components/legend/Legend";
import { MapSizeObserver } from "../components/map/MapSizeObserver";
import { useMap } from "../context/map.context";
import { getLayersBySelection } from "../utils/overlayer.utils";
import { overlayers } from "../config/overlayers";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Source, Substance, Year } from "../types";
import { useStation } from "../hooks";

const selectionDrawerWidth = 350;

export function StationsPage() {
  const position = useMapPosition(mapPositionFromEnv());
  const { selection } = useParameterSelection();
  const mapContext = useMap();

  const activeOverlayers = useMemo(
    () =>
      getLayersBySelection(
        overlayers,
        mapContext.state.activeOverlayers,
        selection.year || undefined
      ),
    [mapContext.state.activeOverlayers, selection.year]
  );

  return render();

  function render() {
    return (
      <AppLayout>
        <Styles>
          <Drawer
            variant="permanent"
            anchor="left"
            className="selection-drawer"
          >
            <Toolbar />
            <Suspense fallback="Loading...">
              <ParameterSelection />
            </Suspense>
          </Drawer>

          <MapContainer
            center={[position.lat, position.lon]}
            zoom={position.zoom}
            className="map-container"
            zoomControl={false}
          >
            <MapSizeObserver />
            <PersistMapPosition />

            <ScaleControl position="bottomleft" imperial={false} />
            <MapZoomControls position="bottomright" />

            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {activeOverlayers.map((overlayer) => (
              <WMSTileLayer
                key={overlayer.wmsLayer.layers!}
                {...overlayer.wmsLayer}
              />
            ))}

            <ActiveStationLayers />
            <Route path="/stations/:stationCode">
              <Suspense fallback={null}>
                {selection.substanceSymbol &&
                  selection.sourceId &&
                  selection.year && (
                    <ActiveStationCentered
                      substanceSymbol={selection.substanceSymbol}
                      sourceId={selection.sourceId}
                      year={selection.year}
                    />
                  )}
              </Suspense>
            </Route>

            <MapControlBase position="topleft">
              {selection.substanceSymbol &&
                selection.sourceId &&
                selection.year &&
                selection.standardId &&
                selection.statisticId && (
                  <Suspense fallback={null}>
                    <StationsSummary
                      substanceSymbol={selection.substanceSymbol}
                      sourceId={selection.sourceId}
                      year={selection.year}
                      standardId={selection.standardId}
                      statisticId={selection.statisticId}
                    />
                  </Suspense>
                )}
            </MapControlBase>

            <MapControlBase position="bottomleft">
              <Legend />
            </MapControlBase>

            {renderStations()}
          </MapContainer>

          <ActionBar />
        </Styles>
      </AppLayout>
    );
  }

  function renderStations() {
    if (!selection.substanceSymbol) return null;
    if (!selection.sourceId) return null;
    if (!selection.year) return null;
    if (!selection.standardId) return null;
    if (!selection.statisticId) return null;

    // return null;
    return (
      <Suspense fallback={null}>
        <StationsOnMap
          substanceSymbol={selection.substanceSymbol}
          sourceId={selection.sourceId}
          year={selection.year}
          standardId={selection.standardId}
          statisticId={selection.statisticId}
        />
      </Suspense>
    );
  }
}

function ActiveStationCentered(props: {
  substanceSymbol: Substance["symbol"];
  sourceId: Source["id"];
  year: Year["year"];
}) {
  const location = useLocation<{ originAction?: "search" }>();
  const history = useHistory();
  const { stationCode } = useParams<{ stationCode: string }>();
  const { substanceSymbol, sourceId, year } = props;
  const { data: station } = useStation(
    substanceSymbol,
    sourceId,
    year,
    stationCode
  );

  const map = useLeafletMap();

  useEffect(() => {
    if (!station) return;

    // only fly to marker on map, when result comes from search
    if (location.state?.originAction === "search") {
      map.flyTo([station.lat, station.lon]);
    }
  }, [history.location.state, location.state, map, station]);
  return null;
}

const Styles = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;

  .map-container,
  .leaflet-container {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

  .selection-drawer {
    flex: 1 0 ${selectionDrawerWidth}px;

    .MuiDrawer-paper {
      width: ${selectionDrawerWidth}px;
      padding: ${({ theme }) => theme.spacing(1)}px;
    }
  }
`;
