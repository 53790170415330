import Axios, { AxiosError } from "axios";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import {
  APIError,
  APIResponse,
  Source,
  Standard,
  Station,
  Statistic,
  Substance,
  SubstanceSourceCategories,
  Year,
} from "../types";

export function useSubstances() {
  async function queryFn(): Promise<Substance[]> {
    const url = "/api/substances";
    const result = await Axios.get<APIResponse<Substance[]>>(url);
    return result.data.data;
  }
  return useQuery("substances", queryFn);
}

export function useSubstance(substanceSymbol: Substance["symbol"]) {
  async function queryFn(): Promise<Substance> {
    const url = `/api/substances/${substanceSymbol}`;
    const result = await Axios.get<APIResponse<Substance>>(url);
    return result.data.data;
  }
  return useQuery(["substances", substanceSymbol], queryFn);
}

export function useSubstanceSourceCategories(
  substanceSymbol: Substance["symbol"]
) {
  async function queryFn(): Promise<SubstanceSourceCategories> {
    const url = `/api/substances/${substanceSymbol}/sourceCategories`;
    const result = await Axios.get<APIResponse<SubstanceSourceCategories>>(url);
    return result.data.data;
  }
  return useQuery(["substance-source-categories", substanceSymbol], queryFn, {
    enabled: substanceSymbol !== "",
  });
}

export type SubstanceConfigMutationData = Substance["customLimits"];

export function useSubstanceConfigUpdateMutation(substanceSymbol: string) {
  const queryClient = useQueryClient();

  async function mutationFn(
    substanceSymbol: string,
    data: SubstanceConfigMutationData
  ): Promise<Substance> {
    const response = await Axios.put(
      `/api/substances/${substanceSymbol}`,
      data
    );
    return response.data.data;
  }

  return useMutation<
    Substance,
    AxiosError<APIError[]>,
    SubstanceConfigMutationData,
    unknown
  >(
    (values: SubstanceConfigMutationData) =>
      mutationFn(substanceSymbol, values),
    {
      onSettled: (data, error, variables) =>
        queryClient.invalidateQueries(["substances"]),
    }
  );
}

export function useSources(
  substanceSymbol: string,
  options?: UseQueryOptions<
    Source[],
    AxiosError<APIError[]>,
    Source[],
    string | Array<string | number>
  >
) {
  async function queryFn(): Promise<Source[]> {
    const url = `/api/substances/${substanceSymbol}/sources`;
    const result = await Axios.get<APIResponse<Source[]>>(url);
    return result.data.data;
  }
  return useQuery(["substances", substanceSymbol, "sources"], queryFn, options);
}

export function useYears(
  substanceSymbol: string,
  sourceId: number | string,
  options?: UseQueryOptions<
    Year[],
    AxiosError<APIError[]>,
    Year[],
    string | Array<string | number>
  >
) {
  async function queryFn(): Promise<Year[]> {
    const url = `/api/substances/${substanceSymbol}/sources/${sourceId}/years`;
    const result = await Axios.get<APIResponse<Year[]>>(url);
    return result.data.data;
  }
  return useQuery(
    ["substances", substanceSymbol, "sources", sourceId, "years"],
    queryFn,
    options
  );
}

export function useSubstanceYears(
  substanceSymbol: string,
  options?: UseQueryOptions<
    Year[],
    AxiosError<APIError[]>,
    Year[],
    string | Array<string | number>
  >
) {
  async function queryFn(): Promise<Year[]> {
    const url = `/api/substances/${substanceSymbol}/years`;
    const result = await Axios.get<APIResponse<Year[]>>(url);
    return result.data.data;
  }
  return useQuery(["substances", substanceSymbol, "years"], queryFn, options);
}

export function useStandards(substanceSymbol: string, sourceId: number) {
  async function queryFn(): Promise<Standard[]> {
    const url = `/api/substances/${substanceSymbol}/sources/${sourceId}/standards`;
    const result = await Axios.get<APIResponse<Standard[]>>(url);
    return result.data.data;
  }
  return useQuery(
    ["substances", substanceSymbol, "sources", sourceId, "standards"],
    queryFn
  );
}

export function useSurfaceWaterStandards(
  substanceSymbol: string,
  options?: UseQueryOptions<
    Standard[],
    AxiosError<APIError[]>,
    Standard[],
    string | Array<string | number | undefined>
  >
) {
  const { data: sources } = useSources(substanceSymbol, {
    enabled: !!options?.enabled,
  });

  const surfaceWaterSource = sources?.find((source) => source.name === "SW");

  async function queryFn(): Promise<Standard[]> {
    const url = `/api/substances/${substanceSymbol}/sources/${surfaceWaterSource?.id}/standards`;
    const result = await Axios.get<APIResponse<Standard[]>>(url);
    return result.data.data;
  }
  return useQuery(
    [
      "substances",
      substanceSymbol,
      "sources",
      surfaceWaterSource?.id,
      "standards",
    ],
    queryFn,
    {
      enabled: !!surfaceWaterSource && !!options?.enabled,
    }
  );
}
export function useGroundWaterStandards(
  substanceSymbol: string,
  options?: UseQueryOptions<
    Standard[],
    AxiosError<APIError[]>,
    Standard[],
    string | Array<string | number | undefined>
  >
) {
  const { data: sources } = useSources(substanceSymbol, {
    enabled: !!options?.enabled,
  });

  const groundWaterSource = sources?.find((source) => source.name === "GW");

  async function queryFn(): Promise<Standard[]> {
    const url = `/api/substances/${substanceSymbol}/sources/${groundWaterSource?.id}/standards`;
    const result = await Axios.get<APIResponse<Standard[]>>(url);
    return result.data.data;
  }
  return useQuery(
    [
      "substances",
      substanceSymbol,
      "sources",
      groundWaterSource?.id,
      "standards",
    ],
    queryFn,
    {
      enabled: !!groundWaterSource && !!options?.enabled,
    }
  );
}

export function useStatistics(standardId: number) {
  async function queryFn(): Promise<Statistic[]> {
    const url = `/api/standards/${standardId}/statistics`;
    const result = await Axios.get<APIResponse<Statistic[]>>(url);
    return result.data.data;
  }
  return useQuery(["standards", standardId, "statistics"], queryFn);
}

export function useStations(
  substanceSymbol: string,
  sourceId: number,
  year: number
) {
  async function queryFn(): Promise<Station[]> {
    const url = `/api/substances/${substanceSymbol}/sources/${sourceId}/years/${year}/stations`;
    const result = await Axios.get<APIResponse<Station[]>>(url);
    return result.data.data;
  }
  return useQuery([substanceSymbol, sourceId, year, "stations"], queryFn);
}

export function useStation(
  substanceSymbol: string,
  sourceId: number,
  year: number,
  stationId: string
) {
  async function queryFn(): Promise<Station> {
    const url = `/api/substances/${substanceSymbol}/sources/${sourceId}/years/${year}/stations/${encodeURIComponent(
      stationId
    )}`;
    const result = await Axios.get<APIResponse<Station>>(url);
    return result.data.data;
  }
  return useQuery(
    [substanceSymbol, sourceId, year, "stations", stationId],
    queryFn
  );
}
