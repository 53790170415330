import { List, ListSubheader, ListItemText } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import { ListItemLink } from "./ListItemLink";
import { useTranslation } from "react-i18next";
import { HistoryState, Substance } from "../types";

export function InformationDownloads(props: {
  substanceSymbol: Substance["symbol"];
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const BASE_URL = window._env_.REACT_APP_API_URL;

  function handleClick(download: HistoryState["download"]) {
    const state = { download };
    history.push({ ...location, state });
  }

  return render();

  function render() {
    return (
      <List
        subheader={
          <ListSubheader disableSticky>{t("label.downloads")}</ListSubheader>
        }
      >
        <ListItemLink
          onClick={() =>
            handleClick({
              url: `${BASE_URL}/api/substances/${props.substanceSymbol}/data/subBasins`,
              visualName: "sub basins",
            })
          }
        >
          <ListItemText primary="Sub bassins" />
        </ListItemLink>

        <ListItemLink
          onClick={() =>
            handleClick({
              url: `${BASE_URL}/api/substances/${props.substanceSymbol}/data/stations`,
              visualName: "stations",
            })
          }
        >
          <ListItemText primary="Stations" />
        </ListItemLink>

        <ListItemLink
          onClick={() =>
            handleClick({
              url: `${BASE_URL}/api/substances/${props.substanceSymbol}/data/waterBodies`,
              visualName: "water bodies",
            })
          }
        >
          <ListItemText primary="Water bodies" />
        </ListItemLink>

        <ListItemLink
          onClick={() =>
            handleClick({
              url: `${BASE_URL}/api/substances/${props.substanceSymbol}/data/filters`,
              visualName: "filters",
            })
          }
        >
          <ListItemText primary="Filters" />
        </ListItemLink>

        <ListItemLink
          onClick={() =>
            handleClick({
              url: `${BASE_URL}/api/substances/${props.substanceSymbol}/data/measurementsGw`,
              visualName: "groundwater measurements",
            })
          }
        >
          <ListItemText primary="Groundwater measurements" />
        </ListItemLink>

        <ListItemLink
          onClick={() =>
            handleClick({
              url: `${BASE_URL}/api/substances/${props.substanceSymbol}/data/measurementsSw`,
              visualName: "surface water measurements",
            })
          }
        >
          <ListItemText primary="Surface water measurements" />
        </ListItemLink>
      </List>
    );
  }
}
