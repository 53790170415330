import { TableRow, TableCell } from "@material-ui/core";
import { LegendStandardType, Standard, Station, Statistic } from "../../types";
import { formatPct, pct } from "../../utils/format.utils";

interface Props {
  stations: Station[];
  standard: Standard;
  statistic: Statistic;
}

export function LowerThenLOQSummaryRows(props: Props) {
  const { stations, standard } = props;

  const legendItem = standard.legend.find(
    (item) => item.type === LegendStandardType.LOWER_THEN_LOQ
  );

  function count(stations: Station[]) {
    return stations.reduce((count: number, station) => {
      if (station.measurementCount === station.loqMeasurementCount)
        return (count += 1);
      else return count;
    }, 0);
  }

  return render();

  function render() {
    if (!legendItem) return null;

    return (
      <TableRow key={legendItem.id}>
        <TableCell>{legendItem.label}</TableCell>
        <TableCell align="right">{count(stations)}</TableCell>
        <TableCell align="right">
          {formatPct(pct(count(stations), stations.length), 0)}
        </TableCell>
      </TableRow>
    );
  }
}
