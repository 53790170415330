import { Grid, StyledComponentProps } from "@material-ui/core";
import styled from "styled-components";

export function PartnerLogos(
  props: { className?: string } & StyledComponentProps
) {
  return (
    <StyledGrid container xs={12} spacing={3} {...props}>
      <Grid
        container
        spacing={3}
        item
        xs={12}
        className="logo-row"
        justify="center"
      >
        <a
          href="http://www.arystalifescience.eu/en"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="arysta lifescience"
            src={process.env.PUBLIC_URL + "/partner-logos/arysta.jpeg"}
          />
        </a>
        <a href="http://www.basf.be/" target="_blank" rel="noreferrer">
          <img
            alt="BASF"
            src={process.env.PUBLIC_URL + "/partner-logos/basf-darkblue.png"}
          />
        </a>
        <a href="http://www.bayer.be/" target="_blank" rel="noreferrer">
          <img
            alt="Bayer cropscience"
            src={process.env.PUBLIC_URL + "/partner-logos/bayer.png"}
          />
        </a>
        <a
          href="http://www.arystalifescience.eu/en"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="Cheminova"
            src={process.env.PUBLIC_URL + "/partner-logos/cheminova.jpeg"}
          />
        </a>
      </Grid>
      <Grid container item xs={12} className="logo-row" justify="center">
        <a href="http://www.syngenta.be/" target="_blank" rel="noreferrer">
          <img
            alt="Syngenta"
            src={process.env.PUBLIC_URL + "/partner-logos/syngenta_small.png"}
          />
        </a>{" "}
        <a href="http://www.vito.be/" target="_blank" rel="noreferrer">
          <img
            alt="VITO"
            src={process.env.PUBLIC_URL + "/partner-logos/vito_basislogo.png"}
          />
        </a>{" "}
        <a href="http://www.adama.com/" target="_blank" rel="noreferrer">
          <img
            alt="Adama"
            src={process.env.PUBLIC_URL + "/partner-logos/adama.jpeg"}
          />
        </a>{" "}
        <a href="http://www.gharda.com/" target="_blank" rel="noreferrer">
          <img
            alt="Gharda"
            src={process.env.PUBLIC_URL + "/partner-logos/gharda.gif"}
          />
        </a>{" "}
        <a href="http://www.oxon.it/" target="_blank" rel="noreferrer">
          <img
            alt="Oxon"
            src={process.env.PUBLIC_URL + "/partner-logos/oxon.png"}
          />
        </a>
      </Grid>
    </StyledGrid>
  );
}

const StyledGrid = styled(Grid)`
  position: relative;
  .logo-row {
    position: relative;
    height: 40px;
    box-sizing: content-box;

    > a {
      position: relative;
      height: 100%;

      img {
        height: 100%;
        mix-blend-mode: multiply;
      }

      &:not(:first-child) {
        margin-left: ${({ theme }) => theme.spacing(5)}px;
      }
    }
  }
`;
