import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export function useLoginSchema() {
  const { t } = useTranslation();

  return Yup.object().shape({
    email: Yup.string().required(
      t("label.isRequiredValidation", { field: t("label.email") })
    ),
    password: Yup.string().required(
      t("label.isRequiredValidation", { field: t("label.password") })
    ),
  });
}
