import {
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  Checkbox,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";

import { Overlayer } from "../../types";
import { overlayers } from "../../config/overlayers";
import { useMap } from "../../context/map.context";
import { useParameterSelection } from "../../hooks/useParamaterSelection";
import {
  getBestYearVariant,
  isOverlayerWithYearVariants,
} from "../../utils/overlayer.utils";
import { useMapPosition } from "../../hooks/useMapPosition";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  onClose: () => void;
}
export function Overlayers(props: Props) {
  const { t } = useTranslation();
  const { selection } = useParameterSelection();

  return render();

  function render() {
    return (
      <ClickAwayListener onClickAway={props.onClose}>
        <Paper>
          <List
            dense
            subheader={
              <ListSubheader>{t("label.layerSelection")}</ListSubheader>
            }
          >
            {overlayers.map((overlayer) => (
              <OverlayerItem
                key={overlayer.key}
                overlayer={overlayer}
                year={selection.year}
              />
            ))}
          </List>
        </Paper>
      </ClickAwayListener>
    );
  }
}

function OverlayerItem(props: { overlayer: Overlayer; year: number | null }) {
  const { overlayer } = props;
  const mapContext = useMap();
  const mapPosition = useMapPosition();

  function isChecked(overlayer: Overlayer) {
    return mapContext.state.activeOverlayers.includes(overlayer.key);
  }

  function getZoomMinMaxInfo(overlayer: Overlayer) {
    if (isChecked(overlayer)) {
      if (mapPosition.zoom < (overlayer.wmsLayer.minZoom || 0)) {
        return "Zoom in to make this map visible";
      }
      if (mapPosition.zoom > (overlayer.wmsLayer.maxZoom || 18)) {
        return "Zoom in to make this map visible";
      }
    }

    return null;
  }

  return render();

  function render() {
    if (!overlayer) return null;

    return (
      <ListItem
        key={overlayer.key}
        dense
        button
        onClick={() =>
          mapContext.dispatch({
            type: "toggleOverlayer",
            payload: overlayer.key,
          })
        }
      >
        <Checkbox
          edge="start"
          checked={isChecked(overlayer)}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": overlayer.key }}
          size="small"
        />

        {isOverlayerWithYearVariants(overlayer) && (
          <ListItemText
            id={overlayer.key}
            primary={`${overlayer.title} ${
              getBestYearVariant(overlayer, props.year || undefined).year
            }`}
            secondary={getZoomMinMaxInfo(overlayer)}
            style={{ whiteSpace: "nowrap" }}
          />
        )}

        {!isOverlayerWithYearVariants(overlayer) && (
          <ListItemText
            id={overlayer.key}
            primary={`${overlayer.title}`}
            style={{ whiteSpace: "nowrap" }}
          />
        )}
      </ListItem>
    );
  }
}
