import { useLocation } from "react-router-dom";
import { MapPosition } from "../types";

const initialPosition: MapPosition = {
  lat: 51.218157751766725,
  lon: 5.079049604319153,
  zoom: 18,
};
export function useMapPosition(initial = initialPosition) {
  const queryParams = useLocation().search;
  const params = new URLSearchParams(queryParams);

  return {
    ...initial,
    lat: parseFloat(params.get("lat") || initial.lat.toString()),
    lon: parseFloat(params.get("lon") || initial.lon.toString()),
    zoom: parseFloat(params.get("zoom") || initial.zoom.toString()),
  };
}

export function mapPositionFromEnv(): MapPosition {
  const [lat, lon, zoom] = window._env_.REACT_APP_INITIAL_POSITION!.split(",");
  return { lat: parseFloat(lat), lon: parseFloat(lon), zoom: parseInt(zoom) };
}
