import React, { Suspense } from "react";
import { AppBar, Box, Toolbar } from "@material-ui/core";
import styled from "styled-components";

import logo from "../assets/logo.png";
import { Search } from "../components/Search";
import { AccountMenu } from "../components/header/AccountMenu";

interface Props {
  children: NonNullable<React.ReactNode>;
  noAuthentication: boolean;
}
export function AppLayout(props: Props) {
  return (
    <Styles>
      <AppBar position="static" color="primary">
        <Toolbar className="toolbar">
          <div className="logo">
            <img src={logo} alt="logo waterprotect" />
            Reporting
          </div>
          <Box flexGrow={1} />
          <Suspense fallback={null}>
            <Search />

            {!props.noAuthentication && <AccountMenu />}
          </Suspense>
        </Toolbar>
      </AppBar>

      <main className="content">{props.children}</main>
    </Styles>
  );
}
AppLayout.defaultProps = { noAuthentication: false };

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .MuiAppBar-root {
    z-index: ${(props) => props.theme.zIndex.drawer + 1};
  }
  .MuiToolbar-root {
    padding-right: ${({ theme }) => theme.spacing(1)}px;
  }

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;

    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    color: #f7f7f7;

    > img {
      height: 100%;
      margin-right: 10px;
    }
  }

  .content {
    flex-grow: 1;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;
