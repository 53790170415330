import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route } from "react-router-dom";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import { config as authConfig } from "marvin-auth-kit";

import "./index.css";
import "leaflet/dist/leaflet.css";
import reportWebVitals from "./reportWebVitals";
import { queryClient } from "./query-client";
import { MapProvider } from "./context/map.context";
import { theme } from "./config/theme";
import { GlobalStyle } from "./styles/GlobalStyle";
import { RootPage } from "./pages/RootPage";
import { PageLoader } from "./components/loading/PageLoader";
import "./i18n";

authConfig.set({ path: "/auth-api" });

const MOUNT_NODE = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <Suspense fallback={<PageLoader />}>
        <MapProvider>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <GlobalStyle />

              <BrowserRouter>
                <Route path="/" component={RootPage} />
              </BrowserRouter>
            </ThemeProvider>
          </MuiThemeProvider>
        </MapProvider>
      </Suspense>
    </QueryClientProvider>
  </React.StrictMode>,
  MOUNT_NODE
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
