import { Suspense } from "react";
import { Grid } from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import styled from "styled-components";

import { DEFAULT, Standard, Station, Substance, Year } from "../../types";
import { useReportsSchema } from "../../schemas/reports.schema";
import { ReportsWaterfall } from "./ReportsWaterfall";
import { SubstanceSelect } from "../form-components/SubstanceSelect";
import { FromSelect } from "../form-components/FromSelect";
import { ToSelect } from "../form-components/ToSelect";
import { SurfaceWaterStandardSelect } from "../form-components/SurfaceWaterStandardSelect";
import { GroundWaterStandardSelect } from "../form-components/GroundWaterStandardSelect";
import { SelectLoading } from "../../components/loading/SelectLoading";
import { ReportsResults } from "../../components/ReportsResults";

export type ReportsFormData = {
  substanceSymbol: Substance["symbol"] | "";
  from: Year["year"] | "";
  to: Year["year"] | "";
  surfaceWaterStandard: Standard["id"] | typeof DEFAULT;
  groundWaterStandard: Standard["id"] | typeof DEFAULT;
};

interface Props {
  className?: string;
  data: ReportsFormData;
  onSubmit: (values: ReportsFormData) => void;
  formRef?: React.MutableRefObject<FormikProps<ReportsFormData> | null>;
  stationCode: Station["code"] | undefined;
}

export function ReportsForm(props: Props) {
  const validationSchema = useReportsSchema();

  const initialValues: ReportsFormData = {
    substanceSymbol: props.data.substanceSymbol,
    from: props.data.from,
    to: props.data.to,
    surfaceWaterStandard: props.data.surfaceWaterStandard,
    groundWaterStandard: props.data.groundWaterStandard,
  };

  return render();

  function render() {
    return (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: ReportsFormData) => {
            console.log(
              "🌀 ~ file: ReportsForm.tsx ~ line 53 ~ values",
              values
            );
            return props.onSubmit(values);
          }}
          enableReinitialize
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ values, isValid }) => {
            return (
              <>
                <StyledForm className={props.className}>
                  <ReportsWaterfall />

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Suspense fallback={<SelectLoading />}>
                        <SubstanceSelect />
                      </Suspense>
                    </Grid>

                    {values.substanceSymbol && (
                      <>
                        <Grid item xs={6}>
                          <Suspense fallback={<SelectLoading />}>
                            <FromSelect />
                          </Suspense>
                        </Grid>
                        <Grid item xs={6}>
                          <Suspense fallback={<SelectLoading />}>
                            <ToSelect />
                          </Suspense>
                        </Grid>
                      </>
                    )}

                    {values.from && values.to && (
                      <>
                        <Grid item xs={12}>
                          <Suspense fallback={<SelectLoading />}>
                            <SurfaceWaterStandardSelect />
                          </Suspense>
                        </Grid>

                        <Grid item xs={12}>
                          <Suspense fallback={<SelectLoading />}>
                            <GroundWaterStandardSelect />
                          </Suspense>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </StyledForm>

                {isValid && (
                  <Suspense fallback="...">
                    <ReportsResults
                      values={values}
                      stationCode={props.stationCode}
                    />
                  </Suspense>
                )}
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}

const StyledForm = styled(Form)`
  .MuiGrid-container:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing(3)}px;
  }
`;
