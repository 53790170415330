import {
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { pct, formatPct, formatHa } from "../../utils/format.utils";
import { Station } from "../../types";
import { StyledTable } from "./StyledTable";

export function LanduseWaterBody(props: { station: Station }) {
  const { station } = props;
  const { landuseWaterBody } = station;
  return render();

  function render() {
    if (!landuseWaterBody) return null;

    return (
      <>
        <Typography variant="h3" gutterBottom>
          Landuse distribution for water body ({station.waterBodyCodes[0]})
        </Typography>

        <StyledTable padding="default">
          <TableHead>
            <TableRow>
              <TableCell>Landuse description</TableCell>
              <TableCell align="right">Area (ha) {station.year} </TableCell>
              <TableCell align="right">% of landuse</TableCell>
              <TableCell align="right">% of drainage basin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {landuseWaterBody.table.map((item) => {
              const landusePct = pct(item.ha, landuseWaterBody.totalLanduse);
              const drainageBasinPct = pct(
                item.ha,
                landuseWaterBody.totalBasinArea
              );
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="right">{formatHa(item.ha)}</TableCell>
                  <TableCell align="right">{formatPct(landusePct)}</TableCell>
                  <TableCell align="right">
                    {formatPct(drainageBasinPct)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </>
    );
  }
}
