import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStation } from "../hooks";
import { Station } from "../types";

type Filter = Station["filters"][0];

interface Props {
  substanceSymbol: string;
  sourceId: number;
  year: number;
  stationCode: string;
}

export function StationDetailTable(props: Props) {
  const { substanceSymbol, sourceId, year, stationCode } = props;
  const { data: station } = useStation(
    substanceSymbol,
    sourceId,
    year,
    stationCode
  );

  return render();

  function render() {
    if (!station) return null;

    return (
      <Styles>
        {station.filters.map((filter) => (
          <FilterTable key={filter.code} filter={filter} />
        ))}
      </Styles>
    );
  }
}

function FilterTable(props: { filter: Filter }) {
  const { filter } = props;
  const { t } = useTranslation();

  return (
    <Table className="filter-table" padding="none">
      <TableBody>
        {Object.entries(filter).map(([key, value]) => (
          <TableRow key={key}>
            <TableCell width={200} variant="head">
              {t(`label.filterKey.${key}`)}
            </TableCell>
            <TableCell>{value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const Styles = styled.div`
  display: flex;
  flex-direction: column;

  .filter-table:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing(3)}px;
  }

  .MuiTableCell-root {
    border-bottom: 0;
  }
`;
