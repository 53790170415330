import { useMemo } from "react";

import { Source, Substance, Standard } from "../../types";
import { useStandards } from "../../hooks";
import { ScaleItem } from "./ScaleItem";

export function LegendStandard(props: {
  substanceSymbol: Substance["symbol"];
  sourceId: Source["id"];
  standardId: Standard["id"];
}) {
  const { substanceSymbol, sourceId } = props;
  const { data: standards } = useStandards(substanceSymbol, sourceId);

  /**
   * Memo
   */
  const standard = useMemo(
    () => standards?.find((standard) => standard.id === props.standardId),
    [props.standardId, standards]
  );

  // render the component
  return render();

  function render() {
    if (!standard) return null;

    return (
      <div>
        {standard.legend.map((item, i) => (
          <ScaleItem key={i} data={item}></ScaleItem>
        ))}
      </div>
    );
  }
}
