import { useHistory, useLocation } from "react-router-dom";

interface IParameterSelection {
  substanceSymbol: string | null;
  sourceId: number | null;
  sourceCategoryId: number | null;
  year: number | null;
  standardId: number | null;
  statisticId: number | null;
}

const initialParameterSelection: IParameterSelection = {
  substanceSymbol: null,
  sourceId: null,
  sourceCategoryId: null,
  year: null,
  standardId: null,
  statisticId: null,
};

export function useParameterSelection(initial = initialParameterSelection) {
  const queryParams = useLocation().search;
  const history = useHistory();
  const params = new URLSearchParams(queryParams);

  return {
    selection: {
      // ...initial,
      substanceSymbol: params.get("substanceSymbol"),
      sourceId: params.get("sourceId")
        ? parseInt(params.get("sourceId")!)
        : null,
      sourceCategoryId: params.get("sourceCategoryId")
        ? parseInt(params.get("sourceCategoryId")!)
        : null,
      year: params.get("year") ? parseInt(params.get("year")!) : null,
      standardId: params.get("standardId")
        ? parseInt(params.get("standardId")!)
        : null,
      statisticId: params.get("statisticId")
        ? parseInt(params.get("statisticId")!)
        : null,
    },

    setSelection: (newSelection: Partial<IParameterSelection>) => {
      if (newSelection.substanceSymbol) {
        params.set("substanceSymbol", newSelection.substanceSymbol);
      } else {
        params.delete("substanceSymbol");
      }
      if (newSelection.sourceId) {
        params.set("sourceId", newSelection.sourceId.toString());
      } else {
        params.delete("sourceId");
      }

      if (newSelection.sourceCategoryId) {
        params.set(
          "sourceCategoryId",
          newSelection.sourceCategoryId.toString()
        );
      } else {
        params.delete("sourceCategoryId");
      }

      if (newSelection.year) {
        params.set("year", newSelection.year.toString());
      } else {
        params.delete("year");
      }

      if (newSelection.standardId) {
        params.set("standardId", newSelection.standardId.toString());
      } else {
        params.delete("standardId");
      }

      if (newSelection.statisticId) {
        params.set("statisticId", newSelection.statisticId.toString());
      } else {
        params.delete("statisticId");
      }

      if (shouldRedirectToStationOverview(newSelection)) {
        history.push({ pathname: "/stations", search: params.toString() });
      } else {
        // stay on current path
        history.push({ search: params.toString() });
      }
    },
  };
}

function shouldRedirectToStationOverview(
  newSelection: Partial<IParameterSelection>
) {
  return Object.keys(newSelection).every((selectionKey) =>
    ["substanceSymbol", "sourceId", "year"].includes(selectionKey)
  );
}
