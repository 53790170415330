import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export function useReportsSchema() {
  const { t } = useTranslation();

  return Yup.object().shape({
    substanceSymbol: Yup.string().required(
      t("label.isRequiredValidation", { field: t("label.substance") })
    ),
    source: Yup.number(),
    from: Yup.number(),
    to: Yup.number()
      .when("from", (from: any, schema: any) => from && schema.min(from))
      .min(Yup.ref("from"), '"To" year can\'t be before "From" year')
      .when("from", (from: number, schema: any) => {
        return schema.test({
          test: (to: number) => !from || to - from < 5,
          message: "The difference between both years can be at most 5 years.",
        });
      }),
    groundWaterStandard: Yup.string(),
    surfaceWaterStandard: Yup.string(),
  });
}
