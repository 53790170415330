import { Alert } from "@material-ui/lab";
import React from "react";
import { APIError } from "../../types";
import { parseServerError } from "../../utils/string.utils";

interface Props {
  data: APIError[];
}

export function ServerErrors(props: Props) {
  const { data: serverErrors } = props;

  return render();

  function render() {
    if (serverErrors.length === 0) return null;

    return (
      <>
        {serverErrors.map((serverError, i) => {
          return (
            <Alert severity="error" key={serverError.key + i}>
              {renderError(serverError)}
            </Alert>
          );
        })}
      </>
    );
  }

  function renderError(error: APIError) {
    if (error.key === "invalid_credentials")
      return "There was an error with your email/password combination. Please try again.";

    return parseServerError(error);
  }
}
