import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";

export const SelectLoading = (props: { width: number }) => {
  return (
    <Styles width={props.width}>
      <Skeleton />
    </Styles>
  );
};
SelectLoading.defaultProps = {
  width: "100%",
};

const Styles = styled.div<{ width?: number }>`
  width: ${({ width }) => width}px;
  height: 100%;
`;
