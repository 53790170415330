import styled from "styled-components";
import { Standard } from "../../types";

interface Props {
  data: Standard["legend"][0];
}

export function ScaleItem(props: Props) {
  const { data } = props;

  return (
    <Styles>
      <div className="scaleVisualContainer">
        <div className="scaleVisual" style={{ backgroundColor: data.color }} />
      </div>
      <div className="scaleLabel">{data.label}</div>
    </Styles>
  );
}
const Styles = styled.div`
  display: flex;
  flex-direction: row;

  & + & {
    margin-top: 2px;
  }

  .scaleVisualContainer {
    position: relative;
    display: flex;
    width: 40px;
    height: 20px;
    margin-right: 10px;
    flex: 0 0 auto;
    align-items: center;
  }

  .scaleVisual {
    width: 100%;
    height: 100%;
    width: 100%;
    height: 100%;
  }

  .scaleValue {
    font-size: 1rem;
    color: #838383;
  }

  .scaleLabel {
    font-size: 13px;
    color: #707070;
  }
`;
