import { MenuItem, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSubstanceYears } from "../../hooks";
import { usePrevious } from "../../hooks/usePrevious";
import { Substance, Year } from "../../types";

interface GenericForm {
  substanceSymbol: Substance["symbol"] | "";
  from: Year["year"] | "";
}

export function FromSelect<T extends GenericForm>(props: {}) {
  const { values, touched, errors, handleChange, handleBlur, setFieldValue } =
    useFormikContext<T>();
  const { t } = useTranslation();
  const { data: years } = useSubstanceYears(values.substanceSymbol, {
    enabled: !!values.substanceSymbol,
  });

  const previousYear = usePrevious<Year["year"] | "">(values.from);

  useEffect(() => {
    if (!previousYear) return;

    if (values.from === "") {
      // if the previousYear is still availlable in new years, reuse
      if (years?.map((s) => s.year).includes(previousYear)) {
        // next cycle to prevent needless freeze
        setTimeout(() => {
          setFieldValue("from", previousYear, false);
        }, 1);
      }
    }
  }, [years, previousYear, setFieldValue, values.from]);

  return render();

  function render() {
    if (!years) return null;

    return (
      <TextField
        id="from"
        name="from"
        label={t("label.from")}
        type="text"
        select={true}
        fullWidth
        value={years.find((year) => year.year === values.from)?.year || ""}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.from && Boolean(errors.from)}
        helperText={touched.from && errors.from}
      >
        {years.map((year) => (
          <MenuItem key={year.year} value={year.year}>
            {year.year}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}
