import styled from "styled-components";
import { Drawer } from "@material-ui/core";

interface Props {
  width: number;
}

const ActionBarDrawer = styled(Drawer)<Props>`
  flex: 0 1 ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;

  &.MuiDrawer-root {
    z-index: ${(props) => props.theme.zIndex.drawer} !important;
  }

  .MuiDrawer-paperAnchorRight {
    margin-right: 60px;
    border-right: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }
  .MuiPaper-root {
    min-width: ${(props) => props.width}px;
    width: ${(props) => props.width}px;
    top: ${({ theme }) => theme.mixins.toolbar.height}px;
    padding: ${({ theme }) => theme.spacing(3, 0)};
  }
`;

ActionBarDrawer.defaultProps = {
  variant: "persistent",
  anchor: "right",
  elevation: 0,
  ModalProps: {
    BackdropProps: {
      invisible: true,
    },
  },
  open: true,
};

export { ActionBarDrawer };
