import {
  List,
  ListSubheader,
  ListItemText,
  Toolbar,
  Divider,
} from "@material-ui/core";
import styled from "styled-components";

import { useSubstance } from "../../hooks";
import { ListItemLink } from "../ListItemLink";
import { useTranslation } from "react-i18next";
import { ActionBarDrawer } from "../ ActionBar/ActionBarDrawer";
import { ActionBarDrawerHeader } from "../ ActionBar/ActionBarDrawerHeader";
import { InformationDownloads } from "../InformationDownloads";

interface Props {
  substanceSymbol: string;
  onClose: () => void;
}

export function Information(props: Props) {
  const { t } = useTranslation();
  const { data: substance } = useSubstance(props.substanceSymbol);

  return render();

  function render() {
    return (
      <StyledActionBarDrawer width={300} onClose={props.onClose}>
        <Toolbar />

        <ActionBarDrawerHeader
          title={t("label.information")}
          onClose={props.onClose}
        />

        <div>
          <List
            subheader={
              <ListSubheader disableSticky>
                {t("label.userManual")}
              </ListSubheader>
            }
          >
            <ListItemLink
              href={process.env.PUBLIC_URL + "/assets/usermanual.pdf"}
            >
              <ListItemText primary="User manual" />
            </ListItemLink>
          </List>

          <Divider />

          {substance && (
            <List
              subheader={
                <ListSubheader disableSticky>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("label.contactPersonSubstance", {
                        substance: substance.name,
                      }),
                    }}
                  />
                </ListSubheader>
              }
            >
              <ListItemLink href={`mailto:${substance.contactPerson}`}>
                <ListItemText primary={substance.contactPerson} />
              </ListItemLink>
            </List>
          )}

          <Divider />

          <List
            subheader={
              <ListSubheader disableSticky>Erosion risk maps</ListSubheader>
            }
          >
            <ListItemText
              className="no-link"
              primary="Erosion risk maps for Flanders are available for 2008-2021."
            />
            <ListItemText
              className="no-link"
              primary="Erosion risk maps are not available for Wallonia."
            />
          </List>

          <Divider />

          <List
            subheader={
              <ListSubheader disableSticky>Landuse maps</ListSubheader>
            }
          >
            <ListItemText
              className="no-link"
              primary="Landuse maps for Flanders are available for 2008-2021."
            />
            <ListItemText
              className="no-link"
              primary="Landuse maps for Wallonia are available for 2015-2021."
            />
          </List>

          <Divider />

          <InformationDownloads substanceSymbol={props.substanceSymbol} />
        </div>
      </StyledActionBarDrawer>
    );
  }
}

const StyledActionBarDrawer = styled(ActionBarDrawer)`
  .MuiPaper-root {
    padding: ${({ theme }) => theme.spacing(3, 0)};
  }

  .MuiListItemText-root.no-link {
    padding: ${({ theme }) => theme.spacing(0, 2)};
  }
  .MuiList-root {
    width: 100%;

    &:not(:first-child) {
      margin-top: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`;
