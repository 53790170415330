import { Container, Grid, Typography, Button } from "@material-ui/core";
import styled from "styled-components";

import { AppLayout } from "../layouts/AppLayout";
import { PartnerLogos } from "../components/PartnerLogos";
import { NavLink } from "react-router-dom";

export function LandingPage() {
  return render();

  function render() {
    return (
      <AppLayout noAuthentication>
        <StyledContainer>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} className="introduction">
              <Typography
                variant="h1"
                className="title"
                gutterBottom
                color="textSecondary"
              >
                WaterProtect is a reporting tool for surface water and
                groundwater monitoring data
              </Typography>
              <Typography variant="body1" paragraph color="textSecondary">
                developed by ADAMA, Arysta LifeScience S.A.S., Bayer Crop
                Science, BASF, Cheminova, Gharda Chemicals Ltd., Oxon Italia
                S.p.A. and Syngenta Crop Protection, in cooperation with VITO
                (Vlaamse Instelling voor Technologisch Onderzoek).
              </Typography>
              <Typography variant="body1" paragraph color="textSecondary">
                The presence of agrochemicals in surface and groundwater is
                monitored by the Flemish and Walloon regional authorities.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={NavLink}
                to="/stations"
              >
                Open tool
              </Button>
            </Grid>

            <Grid item xs={12} md={6} className="cover-image">
              <img
                alt="Example river in Flanders"
                src={process.env.PUBLIC_URL + "/images/landing-page-image.png"}
              />
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <PartnerLogos className="logos" />
            </Grid>
          </Grid>
        </StyledContainer>
      </AppLayout>
    );
  }
}

const StyledContainer = styled(Container)`
  .introduction {
    padding-top: 155px;

    .MuiTypography-h1 {
      font-size: ${({ theme }) => theme.typography.pxToRem(20)};
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
    .MuiTypography-body1 {
      color: #707070;
      font-size: ${({ theme }) => theme.typography.pxToRem(13)};
    }
  }

  .cover-image {
    width: 100%;

    img {
      width: 100%;
      mix-blend-mode: darken;
    }
  }

  .logos {
    margin-top: 100px;
  }
`;
