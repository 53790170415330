import React, { useMemo, useRef } from "react";
import clsx from "clsx";
import { Portal } from "@material-ui/core";
import styled from "styled-components";

// This component will position a Portal at the parent ref's position. Thank the lord for Portals.
export function ToolContent(props: {
  children: React.ReactNode;
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  className?: string;
}) {
  const div = useRef(null);
  const coords = useMemo(() => {
    const viewportOffset = props.parentRef?.current?.getBoundingClientRect();
    const coords = {
      top: viewportOffset?.top,
      left: viewportOffset?.left || 0,
    };
    return coords;
  }, [props.parentRef]);

  return (
    <Portal>
      <Styles className={clsx(props.className)} style={coords} ref={div}>
        {props.children}
      </Styles>
    </Portal>
  );
}

const Styles = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 1200;

  /* &.in { */
  transform: translateX(-100%);
  /* } */
`;
