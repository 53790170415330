import { WMSTileLayerProps } from "react-leaflet";

export interface HistoryState {
  download: {
    url: string;
    visualName: string;
    filename?: string;
  };
}

export interface MapPosition {
  lat: number;
  lon: number;
  zoom: number;
}

export interface Baselayer {
  key: string;
  title: string;
  description?: string;
  attribution?: string;
  url: string;
  image: string;
  isActive: boolean;
}

export type Overlayer = OverlayerSingle | OverlayerWithYearVariants;
export type OverlayerWmsLayer = Overlayer; // & { wmsLayer: WMSTileLayerProps };

export interface OverlayerSingle {
  key: string;
  title: string;
  layers: string;
  wmsLayer: WMSTileLayerProps;
}

export interface OverlayerWithYearVariants {
  key: string;
  title: string;
  variants: Array<{
    layers: string;
    year: number;
  }>;
  wmsLayer: WMSTileLayerProps;
}

export interface APIResponse<T> {
  data: T;
  meta: {
    type: string;
  };
}

export interface APIError {
  key: string;
  message: string;
  arguments: string[];
}

export interface Substance {
  symbol: string;
  contactPerson: string;
  name: string;
  limits: {
    aaEqs: number;
    macEqs: number;
    rac: number;
    gwLimit: number;
  };
  customLimits: {
    caaEqs: number;
    cmacEqs: number;
    crac: number;
    cgwLimit: number;
  };
}

export interface SubstanceSourceCategories {
  belgium: number[];
  flanders: number[];
  wallonia: number[];
}

export interface Source {
  id: number;
  sourceCategoryId: number;
  name: string;
}

export interface Year {
  year: number;
}

export enum LegendStandardType {
  NO_ASSESMENT_POSSIBLE = "NO_ASSESMENT_POSSIBLE",
  LEGEND_ITEM = "LEGEND_ITEM",
  LOWER_THEN_LOQ = "LOWER_THEN_LOQ",
}

export interface Standard {
  id: number;
  description: string;
  name: string;
  legend: Array<{
    label: string;
    id: number;
    upperBound: number;
    color: string;
    type: LegendStandardType;
  }>;
  standardValue: number;
}

export interface Statistic {
  id: number;
  name: string;
}

export interface Station {
  code: string;
  year: number;
  source: number;
  lat: number;
  lon: number;
  name: string;
  communityName: string;
  substanceSymbol: string;
  average: number;
  arithmeticMeanMonthlyAverage: number;
  maximum: number;
  measurementCount: number;
  loqMeasurementCount: number;
  measurements: Array<{
    monthlyMaximum: number | null;
    month: number;
    monthlyAverage: number;
    reportingLimit: number;
    valueCount: number;
  }>;
  waterBodyCodes: string[];
  filters: Array<{
    code: string;
    aquiferCode: string;
    aquiferName: string;
    localCode: string;
    localName: string;
    sampleDepth: number | null;
    waterBodyCode: string;
    waterBodyName: string;
    waterBodyType: string;
    basin: string;
    subBasin: string;
  }>;
  landuseWaterBody: {
    totalLanduse: number;
    totalBasinArea: number;
    table: Array<{
      id: number;
      name: string;
      ha: number;
    }>;
  };
  landuseWaterBodyUpstream: {
    totalLanduse: number;
    totalUpstreamBasinArea: number;
    table: Array<{
      id: number;
      name: string;
      ha: number;
    }>;
  };
  landuseStation: {
    totalLanduse: number;
    totalStationArea: number;
    table: Array<{
      id: number;
      name: string;
      ha: number;
    }>;
  };
}

export enum ReportArea {
  belgium = "belgium",
  flanders = "flanders",
  wallonia = "wallonia",
}

export enum ReportType {
  country = "country",
  exceedance = "exceedance",
  location = "location",
}

export enum ReportFormat {
  pdf = "pdf",
  xlsx = "xlsx",
}

export const DEFAULT = "DEFAULT";
