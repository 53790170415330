import React from "react";
import { Button, ButtonProps, CircularProgress } from "@material-ui/core";

interface ButtonLoadingProps {
  isLoading: boolean;
  loadingText?: React.ReactNode;
  loadingIcon?: React.ReactNode;
}

type Props = ButtonLoadingProps & ButtonProps;

const defaultProps: Partial<Props> = {
  loadingText: "Loading",
  loadingIcon: <CircularProgress size={14} disableShrink />,
};
export function ButtonLoading(props: Props) {
  const { isLoading, loadingText, loadingIcon, children, ...restProps } = props;

  return (
    <Button
      {...restProps}
      disabled={isLoading || restProps.disabled}
      color="primary"
      variant="contained"
      type="submit"
      size="medium"
      startIcon={isLoading && loadingIcon}
    >
      {!isLoading && children}
      {isLoading && loadingText}
    </Button>
  );
}

ButtonLoading.defaultProps = defaultProps;
