import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStatistics } from "../../hooks";
import { Standard, Statistic } from "../../types";

interface Props {
  value: Statistic["id"] | null;
  onChange: (statistic: Statistic["id"] | null) => void;
  standardId: Standard["id"];
}

export function StatisticSelect(props: Props) {
  const { t } = useTranslation();
  const { data: statistics } = useStatistics(props.standardId);

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    props.onChange(event.target.value as number);
  }

  return render();
  function render() {
    if (!statistics) return null;

    return (
      <FormControl fullWidth>
        <InputLabel>{t("label.statistic")}</InputLabel>

        <Select value={props.value || ""} onChange={handleChange}>
          {statistics.map((statistic) => (
            <MenuItem key={statistic.id} value={statistic.id}>
              {t(`label.statisticName.${statistic.name}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
