import { Suspense } from "react";

import { ACTION_BAR_ITEMS } from "./ActionBar";
import { ToolContent as ActionBarContentPanel } from "./ActionBarContentPanel";
import { Overlayers } from "../ActionBarContent/Overlayers";
import { SubstanceConfig } from "../ActionBarContent/SubstanceConfig";
import { Reports } from "../ActionBarContent/Reports";
import { Information } from "../ActionBarContent/Information";
import { Links } from "../ActionBarContent/Links";
import { useParameterSelection } from "../../hooks/useParamaterSelection";
import { PaperLoader } from "../loading/PaperLoading";

interface Props {
  active: ACTION_BAR_ITEMS | null;
  refs: {
    overlayers: React.MutableRefObject<null>;
  };
  onClose: () => void;
}

export function ActionBarActiveContent(props: Props) {
  const { selection } = useParameterSelection();
  return render();

  function render() {
    switch (props.active) {
      case ACTION_BAR_ITEMS.overlayers:
        return (
          <ActionBarContentPanel parentRef={props.refs.overlayers}>
            <Overlayers onClose={props.onClose} />
          </ActionBarContentPanel>
        );

      case ACTION_BAR_ITEMS.substanceConfig:
        if (!selection.substanceSymbol) return null;

        return (
          <Suspense fallback={<PaperLoader width={310} />}>
            <SubstanceConfig
              substanceSymbol={selection.substanceSymbol}
              onClose={props.onClose}
            />
          </Suspense>
        );

      case ACTION_BAR_ITEMS.reports:
        return (
          <Suspense fallback={<PaperLoader width={450} />}>
            <Reports onClose={props.onClose} />
          </Suspense>
        );

      case ACTION_BAR_ITEMS.information:
        if (!selection.substanceSymbol) return null;
        return (
          <Suspense fallback={<PaperLoader width={300} />}>
            <Information
              substanceSymbol={selection.substanceSymbol}
              onClose={props.onClose}
            />
          </Suspense>
        );

      case ACTION_BAR_ITEMS.links:
        return <Links onClose={props.onClose} />;
    }

    return null;
  }
}
