import { MenuItem, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { useGroundWaterStandards } from "../../hooks";
import { DEFAULT, Standard, Substance } from "../../types";

interface GenericForm {
  substanceSymbol: Substance["symbol"] | "";
  groundWaterStandard: Standard["id"];
}

export function GroundWaterStandardSelect<T extends GenericForm>(props: {}) {
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<T>();
  const { t } = useTranslation();
  const { data: groundWaterStandards } = useGroundWaterStandards(
    values.substanceSymbol,
    {
      enabled: !!values.substanceSymbol,
    }
  );
  return render();

  function render() {
    if (!groundWaterStandards) return null;

    return (
      <TextField
        id="groundWaterStandard"
        name="groundWaterStandard"
        label={t("label.groundWaterStandard")}
        type="text"
        select={true}
        fullWidth
        value={values.groundWaterStandard}
        onBlur={handleBlur}
        onChange={handleChange}
        error={
          touched.groundWaterStandard && Boolean(errors.groundWaterStandard)
        }
        helperText={touched.groundWaterStandard && errors.groundWaterStandard}
      >
        <MenuItem value={DEFAULT}>Default</MenuItem>
        {groundWaterStandards.map((standard) => (
          <MenuItem
            key={["ground-water-standard", standard.id].join()}
            value={standard.id}
          >
            {standard.name}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}
