import { AtRule } from "csstype";

export type JSSFontface = AtRule.FontFace;

const AvenirFont: JSSFontface = {
  fontFamily: "Avenir",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `url(${require("../assets/fonts/Avenir.woff")}) format('woff')`,
};

// const AvenirLightFont: JSSFontface = {
//   fontFamily: "AvenirLT-Light",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 100,
//   src: `local('AvenirLT-Light'),
//         url(${require("../assets/fonts/webFonts/AvenirLTLight/font.woff2")}) format('woff2'),
//         url(${require("../assets/fonts/webFonts/AvenirLTLight/font.woff")}) format('woff')`,
// };

// const AvenirLightObliqueFont: JSSFontface = {
//   fontFamily: "AvenirLT-LightOblique",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 100,
//   src: `local('AvenirLT-LightOblique'),
//         url(${require("../assets/fonts/webFonts/AvenirLTLightOblique/font.woff2")}) format('woff2'),
//         url(${require("../assets/fonts/webFonts/AvenirLTLightOblique/font.woff")}) format('woff')`,
// };

// const AvenirMediumFont: JSSFontface = {
//   fontFamily: "AvenirLT-Medium",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `local('AvenirLT-Medium'),
//         url(${require("../assets/fonts/webFonts/AvenirLTMedium/font.woff2")}) format('woff2'),
//         url(${require("../assets/fonts/webFonts/AvenirLTMedium/font.woff")}) format('woff')`,
// };

// const AvenirMediumObliqueFont: JSSFontface = {
//   fontFamily: "AvenirLT-MediumOblique",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `local('AvenirLT-MediumOblique'),
//         url(${require("../assets/fonts/webFonts/AvenirLTMediumOblique/font.woff2")}) format('woff2'),
//         url(${require("../assets/fonts/webFonts/AvenirLTMediumOblique/font.woff")}) format('woff')`,
// };

// const AvenirHeavyFont: JSSFontface = {
//   fontFamily: "AvenirLT-Heavy",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 700,
//   src: `local('AvenirLT-Heavy'),
//         url(${require("../assets/fonts/webFonts/AvenirLTHeavy/font.woff2")}) format('woff2'),
//         url(${require("../assets/fonts/webFonts/AvenirLTHeavy/font.woff")}) format('woff')`,
// };

// const AvenirHeavyObliqueFont: JSSFontface = {
//   fontFamily: "AvenirLT-HeavyOblique",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 700,
//   src: `local('AvenirLT-HeavyOblique'),
//         url(${require("../assets/fonts/webFonts/AvenirLTHeavyOblique/font.woff2")}) format('woff2'),
//         url(${require("../assets/fonts/webFonts/AvenirLTHeavyOblique/font.woff")}) format('woff')`,
// };

// const AvenirBlackFont: JSSFontface = {
//   fontFamily: "AvenirLT-Black",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 900,
//   src: `local('AvenirLT-Black'),
//         url(${require("../assets/fonts/webFonts/AvenirLTBlack/font.woff2")}) format('woff2'),
//         url(${require("../assets/fonts/webFonts/AvenirLTBlack/font.woff")}) format('woff')`,
// };

// const AvenirBlackObliqueFont: JSSFontface = {
//   fontFamily: "AvenirLT-BlackOblique",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 900,
//   src: `local('AvenirLT-BlackOblique'),
//         url(${require("../assets/fonts/webFonts/AvenirLTBlackOblique/font.woff2")}) format('woff2'),
//         url(${require("../assets/fonts/webFonts/AvenirLTBlackOblique/font.woff")}) format('woff')`,
// };

export const fontFaces = [
  AvenirFont,
  // AvenirLightFont,
  // AvenirLightObliqueFont,
  // AvenirMediumFont,
  // AvenirMediumObliqueFont,
  // AvenirHeavyFont,
  // AvenirHeavyObliqueFont,
  // AvenirBlackFont,
  // AvenirBlackObliqueFont,
];
