import { memo, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { StationMarker } from "./StationMarker";
import { Source, Standard, Statistic, Substance, Year } from "../types";
import { useStandards, useStations, useStatistics } from "../hooks";

interface Props {
  substanceSymbol: Substance["symbol"];
  sourceId: Source["id"];
  year: Year["year"];
  standardId: Standard["id"];
  statisticId: Statistic["id"];
}

export const StationsOnMap = memo((props: Props) => {
  const { substanceSymbol, sourceId, year, standardId, statisticId } = props;

  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ stationCode?: string }>();
  const activeStationCode = decodeURIComponent(params.stationCode || "");

  // fetch data
  const { data: stations } = useStations(substanceSymbol, sourceId, year);
  const { data: standards } = useStandards(substanceSymbol, sourceId);
  const { data: statistics } = useStatistics(standardId);

  /**
   * Memo
   */
  const activeStandard = useMemo(
    () => standards?.find((standard) => standard.id === standardId),
    [standardId, standards]
  );
  const activeStatistic = useMemo(
    () => statistics?.find((statistic) => statistic.id === statisticId),
    [statisticId, statistics]
  );

  // render the component
  return render();

  function render() {
    if (!stations) return null;
    if (!activeStandard) return null;
    if (!activeStatistic) return null;

    return (
      <div>
        {stations.map((station) => {
          return (
            <StationMarker
              key={station.code}
              active={activeStationCode === station.code}
              station={station}
              standard={activeStandard}
              statistic={activeStatistic}
              onClick={() => {
                history.push({
                  pathname: `/stations/${encodeURIComponent(station.code)}`,
                  search: location.search,
                  state: location.state,
                });
              }}
              onShowDetails={() => {
                history.push({
                  pathname: `/stations/${encodeURIComponent(
                    station.code
                  )}/detail`,
                  search: location.search,
                });
              }}
              onClose={() => {
                history.push({
                  pathname: `/stations`,
                  search: location.search,
                });
              }}
            />
          );
        })}
      </div>
    );
  }
});
