import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export function useSubstanceConfigSchema() {
  const { t } = useTranslation();

  return Yup.object().shape({
    crac: Yup.number().required(
      t("label.isRequiredValidation", { field: t("label.crac") })
    ),
    cmacEqs: Yup.number().required(
      t("label.isRequiredValidation", { field: t("label.cmacEqs") })
    ),
    cgwLimit: Yup.number().required(
      t("label.isRequiredValidation", { field: t("label.cgwLimit") })
    ),
    caaEqs: Yup.number().required(
      t("label.isRequiredValidation", { field: t("label.caaEqs") })
    ),
  });
}
