import { createContext, useContext, useReducer } from "react";
import { Overlayer } from "../types";
import { xor } from "lodash-es";

type Action =
  // | { type: "setOverlayers"; payload: Overlayer[] }
  { type: "toggleOverlayer"; payload: Overlayer["key"] };
type Dispatch = (action: Action) => void;
type State = { activeOverlayers: Array<Overlayer["key"]> };
type MapProviderProps = { children: React.ReactNode };

const MapStateContext =
  createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function mapReducer(state: State, action: Action) {
  switch (action.type) {
    case "toggleOverlayer": {
      return {
        ...state,
        activeOverlayers: xor(state.activeOverlayers, [action.payload]),
      };
    }
    default: {
      throw new Error(`Unhandled action type ${action}`);
    }
  }
}

function MapProvider({ children }: MapProviderProps) {
  const [state, dispatch] = useReducer(mapReducer, {
    activeOverlayers: [],
  });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <MapStateContext.Provider value={value}>
      {children}
    </MapStateContext.Provider>
  );
}

function useMap() {
  const context = useContext(MapStateContext);
  if (context === undefined) {
    throw new Error("useMap must be used within a MapProvider");
  }
  return context;
}

export { MapProvider, useMap };
