import { useEffect, useRef } from "react";
import { CircleMarker } from "react-leaflet";

import { Standard, Station, Statistic } from "../types";
import * as StationUtils from "../utils/station.utils";
import { StationTooltip } from "./StationTooltip";
import { StationPopup } from "./StationPopup";
import { useLocation } from "react-router-dom";

export function StationMarker(props: {
  active: boolean;
  station: Station;
  standard: Standard;
  statistic: Statistic;
  onClick: () => void;
  onShowDetails: () => void;
  onClose: () => void;
}) {
  const location = useLocation();
  const { station, standard, statistic } = props;
  const color = StationUtils.getColor(station, standard, statistic);
  const ref = useRef<any>(null!!);

  // trigger fire when station is active from outside (url param)
  useEffect(() => {
    // do not trigger when on the detail page (this interfers with the modal backdrop click)
    if (location.pathname.includes("/detail")) return;

    if (props.active) ref.current.fire("click");
  }, [location.pathname, props.active, station.code]);

  return (
    <CircleMarker
      ref={ref}
      key={station.code}
      center={[station.lat, station.lon]}
      radius={props.active ? 12 : 6}
      pathOptions={{
        color: "black",
        fillColor: color,
        stroke: true,
        fillOpacity: 0.9,
        weight: 1,
      }}
      eventHandlers={{
        popupopen: () => {
          props.onClick();
        },
        popupclose: () => {
          props.onClose();
        },
      }}
    >
      {/* Hide the tooltip when the popup comes into game */}
      {!props.active && <StationTooltip station={station} />}

      {/* Popup active state is managed by Leaflet */}
      <StationPopup
        station={station}
        onClose={props.onClose}
        onShowDetails={props.onShowDetails}
      />
    </CircleMarker>
  );
}
