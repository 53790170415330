import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSubstances } from "../../hooks";
import { Substance } from "../../types";

interface Props {
  value: Substance["symbol"] | null;
  onChange: (substanceSymbol: string | null) => void;
}

export function SubstanceSelect(props: Props) {
  const { t } = useTranslation();
  const { data: substances } = useSubstances();

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    props.onChange(event.target.value as string);
  }

  // render the component
  return render();

  function render() {
    if (!substances) return null;

    return (
      <FormControl fullWidth>
        <InputLabel shrink>{t("label.substance")}</InputLabel>

        <Select value={props.value || ""} onChange={handleChange} displayEmpty>
          <MenuItem
            value=""
            // disabled
          >
            {t("label.none")}
          </MenuItem>
          {substances.map((substance) => (
            <MenuItem key={substance.symbol} value={substance.symbol}>
              {substance.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
