import styled from "styled-components";
import { useStation } from "../../hooks";
import { StyledTable } from "./StyledTable";
import { LanduseWaterBody } from "./LanduseWaterBody";
import { LanduseWaterBodyUpstream } from "./LanduseWaterBodyUpstream";
import { LanduseStation } from "./LanduseStation";

interface Props {
  substanceSymbol: string;
  sourceId: number;
  year: number;
  stationCode: string;
}

export function StationLandUseDistribution(props: Props) {
  const { substanceSymbol, sourceId, year, stationCode } = props;
  const { data: station } = useStation(
    substanceSymbol,
    sourceId,
    year,
    stationCode
  );

  return render();

  function render() {
    if (!station) return null;
    return (
      <Styles>
        <LanduseWaterBody station={station} />
        <LanduseWaterBodyUpstream station={station} />
        <LanduseStation station={station} />
      </Styles>
    );
  }
}

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${StyledTable} {
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  }
`;
