import { TFunction } from "i18next";

interface Link {
  text: string;
  url: string;
}

interface LinksSection {
  title: string;
  links: Link[];
}

type LinksConfiguration = LinksSection[];

export function linksConfiguration(t: TFunction): LinksConfiguration {
  return [
    {
      title: t("label.surfaceWaterFlanders"),
      links: [
        {
          text: "River basins in Flanders",
          url: "http://www.integraalwaterbeleid.be/nl/bekkens/",
        },
        {
          text: "Surface water bodies in Flanders",
          url: "http://www.integraalwaterbeleid.be/nl/geoloket/overzicht-oppervlaktewaterlichamen/",
        },
        { text: "VMM geoportals", url: "http://geoloket.vmm.be/Geoviews/" },
        {
          text: "VMM geoportal Geoview Water",
          url: "http://geoloket.vmm.be/Geoviews/",
        },
        {
          text: "CIW geoportals",
          url: "http://www.integraalwaterbeleid.be/nl/geoloket",
        },
        {
          text: "CIW geoportal River basin management plans",
          url: "https://www.integraalwaterbeleid.be/nl/geoloket/geoloket-stroomgebiedbeheerplannen/",
        },
      ],
    },
    {
      title: t("label.surfaceWaterWallonia"),
      links: [
        {
          text: "Surface water bodies in Wallonia",
          url: "http://eau.wallonie.be/spip.php?rubrique66",
        },
        {
          text: "DGARNE portal Aquaphy",
          url: "http://environnement.wallonie.be/aquaphyc/#/aquaphyc/home",
        },
        {
          text: " Wallonia environment portal on Wate",
          url: "http://etat.environnement.wallonie.be",
        },
      ],
    },
    {
      title: t("label.groundWaterFlanders"),
      links: [
        {
          text: "Groundwater bodies in Flanders",
          url: "http://www.integraalwaterbeleid.be/nl/geoloket/overzicht-grondwaterlichamen/",
        },
        {
          text: "Database Subsoil Flanders (DOV)",
          url: "https://www.dov.vlaanderen.be/",
        },
        {
          text: "DOV geoportal DOV-Verkenner",
          url: "https://www.dov.vlaanderen.be/portaal/?module=verkenner",
        },
      ],
    },
    {
      title: t("label.groundWaterWallonia"),
      links: [
        {
          text: "Groundwater bodies in Wallonia",
          url: "http://eau.wallonie.be/spip.php?rubrique67",
        },
        {
          text: "Wallonia environment portal on Water",
          url: "http://etat.environnement.wallonie.be",
        },
      ],
    },
    {
      title: t("label.GISPortals"),
      links: [
        {
          text: "Flanders: Geopunt Vlaanderen",
          url: "	https://www.geopunt.be/",
        },
        {
          text: "Wallonia: Géoportail de la Wallonie",
          url: "https://geoportail.wallonie.be",
        },
      ],
    },
    {
      title: t("label.waterFrameworkDirective"),
      links: [
        {
          text: "Flanders: Kaderrichtlijn Water",
          url: "https://www.integraalwaterbeleid.be/nl/regelgeving/kaderrichtlijn-water",
        },
        {
          text: "Wallonia:  Directive-cadre sur l’Eau",
          url: "http://eau.wallonie.be/spip.php?rubrique1",
        },
      ],
    },
    {
      title: t("label.waterManagementPlans"),
      links: [
        {
          text: "Flanders: Stoomgebiedbeheerplannen",
          url: "https://www.integraalwaterbeleid.be/nl/stroomgebiedbeheerplannen",
        },
        {
          text: "Wallonia: Plans de gestion",
          url: "http://eau.wallonie.be/spip.php?rubrique1",
        },
      ],
    },
  ];
}
