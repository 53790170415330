import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";

export const PageLoader = () => {
  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      justify="center"
      style={{ height: "100%" }}
      spacing={3}
    >
      <Grid item style={{ textAlign: "center" }}>
        <CircularProgress />
        <Typography variant="h2" color="primary">
          Loading...
        </Typography>
      </Grid>
    </Grid>
  );
};
