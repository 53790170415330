import { useEffect } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { useSubstanceYears } from "../../hooks";
import { usePrevious } from "../../hooks/usePrevious";
import { Substance, Year } from "../../types";

interface GenericForm {
  substanceSymbol: Substance["symbol"] | "";
  to: Year["year"] | "";
}

export function ToSelect<T extends GenericForm>(props: {}) {
  const { values, touched, errors, handleChange, handleBlur, setFieldValue } =
    useFormikContext<T>();
  const { t } = useTranslation();
  const { data: years } = useSubstanceYears(values.substanceSymbol, {
    enabled: !!values.substanceSymbol,
  });

  const previousYear = usePrevious<Year["year"] | "">(values.to);
  const previousYears = usePrevious<Year[]>(years || []);

  useEffect(() => {
    if (!previousYears) return;
    if (!previousYear) return;

    if (values.to === "") {
      if (previousYears.map((s) => s.year).includes(previousYear)) {
        // next cycle to prevent needless freeze
        setTimeout(() => {
          setFieldValue("to", previousYear, true);
        }, 1);
      }
    }
  }, [previousYear, previousYears, setFieldValue, values.to]);

  return render();

  function render() {
    if (!years) return null;

    return (
      <TextField
        id="to"
        name="to"
        label={t("label.to")}
        type="text"
        select={true}
        fullWidth
        value={years.find((year) => year.year === values.to)?.year || ""}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.to && Boolean(errors.to)}
        helperText={touched.to && errors.to}
      >
        {years.map((year) => (
          <MenuItem key={year.year} value={year.year}>
            {year.year}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}
