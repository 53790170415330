import { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { QueryErrorResetBoundary } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { LoginRoute, PrivateRoute } from "marvin-auth-kit";

import { StationsPage } from "./StationsPage";
import { LoginPage } from "./LoginPage";
import { LandingPage } from "./LandingPage";
import { RootErrorFallback } from "../components/error-boundaries/RootErrorBoundary";
import { PageLoader } from "../components/loading/PageLoader";
import { StationDetailPage } from "./StationDetailPage";
import { DownloadFileBlob } from "../components/DownloadFileBlob";

export function RootPage() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary FallbackComponent={RootErrorFallback} onReset={reset}>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <Route path="/start" component={LandingPage} />

              <LoginRoute
                path={["/login"]}
                redirectTo="/stations"
                component={LoginPage}
                loader={<PageLoader />}
              />

              <Route path="/stations">
                <PrivateRoute
                  path={["/stations/:stationCode", "/stations"]}
                  loader={<PageLoader />}
                  component={StationsPage}
                />

                <PrivateRoute
                  exact
                  path="/stations/:stationCode/detail"
                  loader={<PageLoader />}
                  component={StationDetailPage}
                />
              </Route>
              <Redirect to="/start" />
            </Switch>

            <DownloadFileBlob />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
