import { toNumber } from "lodash";
import {
  DEFAULT,
  ReportArea,
  ReportFormat,
  ReportType,
  Source,
  Standard,
  Station,
  Substance,
  SubstanceSourceCategories,
  Year,
} from "../types";

export interface ReportParams {
  symbol: Substance["symbol"];
  sourceCategoryId: Source["sourceCategoryId"] | "";
  fromYear: Year["year"] | "";
  toYear: Year["year"] | "";
  standardId: Standard["id"] | typeof DEFAULT;
  area: ReportArea;
  type: ReportType;
  station?: Station["code"];
}

export interface ReportLinks {
  [ReportFormat.pdf]: string;
  [ReportFormat.xlsx]: string;
}

export const surfaceWaterSourceCategoryId = Number(
  window._env_.REACT_APP_SURFACEWATER_SOURCE_CATEGORY_ID
);
export const groundWaterSourceCategoryId = Number(
  window._env_.REACT_APP_GROUNDWATER_SOURCE_CATEGORY_ID
);

export function hasSurfaceWaterIn(
  region: ReportArea,
  sourceCategories: SubstanceSourceCategories
) {
  return sourceCategories[region].includes(
    toNumber(surfaceWaterSourceCategoryId)
  );
}

export function hasGroundWaterIn(
  region: ReportArea.belgium | ReportArea.wallonia | ReportArea.flanders,
  sourceCategories: SubstanceSourceCategories
) {
  return sourceCategories[region].includes(
    toNumber(groundWaterSourceCategoryId)
  );
}

export function getReportLinks(params: ReportParams): ReportLinks {
  return {
    pdf: getReportLink(params, ReportFormat.pdf),
    xlsx: getReportLink(params, ReportFormat.xlsx),
  };
}

export function getReportLink(
  params: ReportParams,
  format: ReportFormat
): string {
  const BASE_URL = window._env_.REACT_APP_API_URL;

  // merge params without standardId
  const { standardId, ...baseParams } = { ...params, format };

  // construct the queryString to use in API request
  const query = new URLSearchParams();
  for (let key in baseParams) {
    const value = baseParams[key as keyof typeof baseParams];
    if (value) query.append(key, value.toString());
  }

  if (standardId !== DEFAULT) {
    query.append("standardId", standardId.toString());
  }

  return `${BASE_URL}/api/report?${query.toString()}`;
}
