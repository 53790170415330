import { fade, Popper, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Search as SearchIcon } from "@material-ui/icons";
import { ChangeEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useStations } from "../hooks";
import { useParameterSelection } from "../hooks/useParamaterSelection";

import { Station, Substance, Source, Year } from "../types";
import { match, parse } from "../utils/autosuggest-highlight.utils";
import { useTranslation } from "react-i18next";

export function Search(props: {}) {
  const location = useLocation();
  const history = useHistory();

  const { selection } = useParameterSelection();

  function handleChange(event: ChangeEvent<{}>, value: string | Station) {
    const stationCode = encodeURIComponent((value as Station).code);
    history.push({
      pathname: `/stations/${stationCode}`,
      search: location.search,
      state: { originAction: "search" },
    });
  }

  return render();

  function render() {
    if (!selection.substanceSymbol) return null;
    if (!selection.sourceId) return null;
    if (!selection.year) return null;

    return (
      <SearchResults
        substanceSymbol={selection.substanceSymbol}
        sourceId={selection.sourceId}
        year={selection.year}
        onChange={handleChange}
      />
    );
  }
}

function SearchResults(props: {
  substanceSymbol: Substance["symbol"];
  sourceId: Source["id"];
  year: Year["year"];
  onChange: (event: ChangeEvent<{}>, value: string | Station) => void;
}) {
  const { t } = useTranslation();
  const { data: stations, error } = useStations(
    props.substanceSymbol,
    props.sourceId,
    props.year
  );

  return render();

  function render() {
    return (
      <Styles>
        <div className="search">
          <div className="search-icon">
            <SearchIcon />
          </div>
          <Autocomplete
            disableClearable
            freeSolo
            options={stations || Array<Station>()}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              return false;
            }}
            onChange={(event: ChangeEvent<{}>, value: string | Station) => {
              event.preventDefault();
              event.stopPropagation();
              props.onChange(event, value);
            }}
            loading={stations === undefined && !error}
            loadingText={t("label.lookingForStations")}
            noOptionsText={t("label.noStationsFound")}
            getOptionLabel={(option) => stationSearchVal(option)}
            classes={{ option: "option" }}
            PopperComponent={StyledPopper}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  placeholder={t("label.searchForStation")}
                  margin="none"
                  variant="standard"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    disableUnderline: true,
                    classes: { root: "input-root", input: "input-input" },
                  }}
                />
              );
            }}
            renderOption={(option, { inputValue }) => {
              const searchVal = stationSearchVal(option);
              const matches = match(searchVal, inputValue, true);
              const parts = parse(searchVal, matches);

              return (
                <>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}
                </>
              );
            }}
          />
        </div>
      </Styles>
    );
  }
}

function stationSearchVal(station: Station) {
  return [station.code, station.name, station.communityName].join(" - ");
}
const Styles = styled.div`
  .search {
    position: relative;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    background-color: ${({ theme }) => fade(theme.palette.common.white, 0.15)};

    &:hover {
      background-color: ${({ theme }) =>
        fade(theme.palette.common.white, 0.25)};
    }
    margin-right: ${({ theme }) => theme.spacing(2)}px;
    margin-left: 0;
    width: 100%;
  }
  .search-icon {
    padding: ${({ theme }) => theme.spacing(0, 2)};
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-root {
    color: inherit;
    width: auto;
  }

  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child,
  .input-input {
    padding: ${({ theme }) => theme.spacing(1, 1, 1, 0)};

    // vertical padding + font size from searchIcon
    padding-left: calc(1em + ${({ theme }) => theme.spacing(4)}px);
    transition: ${({ theme }) => theme.transitions.create("width")};
    width: 100%;
    min-width: 200px;
  }

  .MuiAutocomplete-option {
    border: 4px solid gold;
  }
`;

const StyledPopper = styled(Popper)`
  .option {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
