import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
body,
html {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

#root {
  height: 100%;
}


.leaflet-div-icon{
  background-color: transparent;
  border: 0;
}

.MuiTableCell-head{
  font-weight: 900;
}
`;
