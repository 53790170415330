import React from "react";
import { useHistory } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { AccountCircle as AccountCircleIcon } from "@material-ui/icons";
import { auth } from "marvin-auth-kit";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

export function AccountMenu() {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  /**
   * Functions
   */
  function handleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleLogout() {
    queryClient.clear();
    auth.logout();
    history.push("/login");
  }

  // render the component
  return render();

  function render() {
    return (
      <>
        <IconButton aria-haspopup="true" onClick={handleMenu} color="inherit">
          <AccountCircleIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>{t("label.logout")}</MenuItem>
        </Menu>
      </>
    );
  }
}
