import { useMap } from "react-leaflet";
import { Box, Fab } from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";

import { MapControlBase } from "./MapControlBase";

interface MapZoomControlProps extends L.ControlOptions {}

export function MapZoomControls(props: MapZoomControlProps) {
  const { ...controlOptions } = props;
  const map = useMap();

  function handleZoom(direction: "in" | "out") {
    if (direction === "in") map.setZoom(map.getZoom() + 1);
    if (direction === "out") map.setZoom(map.getZoom() - 1);
  }

  return render();

  function render() {
    return (
      <MapControlBase {...controlOptions}>
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            <Fab size="small" onClick={(e) => handleZoom("in")}>
              <AddIcon fontSize="small" />
            </Fab>
          </Box>
          <Box mb={1}>
            <Fab size="small" onClick={(e) => handleZoom("out")}>
              <RemoveIcon fontSize="small" />
            </Fab>
          </Box>
        </Box>
      </MapControlBase>
    );
  }
}
