import {
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Station } from "../../types";
import { pct, formatPct, formatHa } from "../../utils/format.utils";
import { StyledTable } from "./StyledTable";

export function LanduseWaterBodyUpstream(props: { station: Station }) {
  const { station } = props;
  const { landuseWaterBodyUpstream } = station;
  return render();

  function render() {
    if (!landuseWaterBodyUpstream) return null;

    return (
      <>
        <Typography variant="h3" gutterBottom>
          Landuse distribution for the water body and its upstream water bodies
          ({station.waterBodyCodes.join(", ")})
        </Typography>
        <StyledTable padding="default">
          <TableHead>
            <TableRow>
              <TableCell>Landuse description</TableCell>
              <TableCell align="right">Area (ha) {station.year} </TableCell>
              <TableCell align="right">% of landuse</TableCell>
              <TableCell align="right">% of drainage basin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {landuseWaterBodyUpstream.table.map((item) => {
              const landusePct = pct(
                item.ha,
                landuseWaterBodyUpstream.totalLanduse
              );
              const basinPct = pct(
                item.ha,
                landuseWaterBodyUpstream.totalUpstreamBasinArea
              );
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="right">{formatHa(item.ha)}</TableCell>
                  <TableCell align="right">{formatPct(landusePct)}</TableCell>
                  <TableCell align="right">{formatPct(basinPct)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </>
    );
  }
}
